<mat-card class="example-card">
    <mat-card-content>
        <div class="example-container mt-10" *ngIf="errorshow == true">
            <div>NO Data Found</div>>
        </div>
        <div class="example-container mt-10">

            <div class="row g-4">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Order ID :</label>
                </div>
                <div class="col-md-3 text-left">
                    <span for="inputBillTo" class="order-data"> {{ order_id}} </span>
                </div>
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Order Date :</label>
                </div>
                <div class="col-md-4 text-left">
                    <span for="inputBillTo" class="order-data"> {{order_date}} </span>
                </div>
                <div class="col-md-1 text-left">

                    <button mat-raised-button routerLink="/Orders">Cancel </button>
                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">App :</label>
                </div>
                <div class="col-md-3 text-left">
                    <span for="inputBillTo" class="order-data"> {{app_name}} </span>
                </div>
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">client :</label>
                </div>
                <div class="col-md-4 text-left">
                    <span for="inputBillTo" class="order-data" *ngIf="client_id ==null"> -</span>
                    <span for="inputBillTo" class="order-data" *ngIf="client_id !== null"> {{client_name}} </span>
                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Partner :</label>
                </div>
                <div class="col-md-4 text-left">
                    <span for="inputBillTo" class="order-data" *ngIf="partner_id ==null"> -</span>
                    <span for="inputBillTo" class="order-data" *ngIf="partner_id !== null"> {{partner_name}} </span>

                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-1 text-left">
                    <label for="inputBillTo" class="form-label">Status :</label>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-1 text-left">
                    <div class="row">
                        <div class="col-md-12 p-8 text-center" style=" border: #0dcaf033;  background-color: #0dcaf033;"
                            *ngIf="order_status === 'I'">
                            <span for="inputBillTo" class="status-data" style="color: #174A84;">In-Process</span>
                        </div>
                        <div class="col-md-12  text-center p-8"
                            style="  border: #d0d1d7;  background-color: #d0d1d7;" *ngIf="order_status === 'R'">
                            <span for="inputBillTo" class="status-data" style="color: #474950">Registered</span>
                        </div>
                        <div  class="col-md-12  text-center p-8"
                        style="  border: #b9cb2933;  background-color: #b9cb2933;" *ngIf="order_status === 'E'">
                            <span for="inputBillTo" class="status-data" style="color: #cd8704;">Error</span>
                        </div>
                        <div class="col-md-12  text-center p-8"
                            style="  border: #0dcaf033;  background-color: #9ad7cb;" *ngIf="order_status === 'S'">
                            <span for="inputBillTo" class="status-data" style="color: #146e5d">Success</span>
                        </div>
                        <div class="col-md-12 text-center p-8"
                            style="  border: #b58888;  background-color: #b58888;" *ngIf="order_status === 'F'">
                            <span for="inputBillTo" class="status-data" style="color: #ed0909">Fail</span>
                        </div>
                        <div class="col-md-12 text-center p-8"
                            style="  border: #746e6e;  background-color: #746e6e;" *ngIf="order_status === 'C'">
                            <span for="inputBillTo" class="status-data" style="color: #000000">Cancelled</span>
                        </div>
                        <div class="col-md-12 text-center p-8"
                            style="  border: #e1b69a;  background-color: #e1b69a;" *ngIf="order_status === 'K'">
                            <span for="inputBillTo" class="status-data" style="color: #FF6600">Killed</span>
                        </div>
                        <div class="col-md-12 text-center p-8"
                            style="  border: #be9ed1;  background-color: #be9ed1;" *ngIf="order_status === 'W'">
                            <span for="inputBillTo" class="status-data" style="color: #A200FF">Wrong parameter</span>
                        </div>
                        <div class="col-md-12"
                             *ngIf="order_status ==null">
                            <span for="inputBillTo" class="status-data" >-</span>
                        </div>
                    </div>
                </div>
                <div class="col-md-1"></div>
                <div class="col-md-8">
                    <div class="row">
                        <div class="col-md-2 p-8 text-center"
                            style=" border: #0dcaf033;  background-color: #0dcaf033;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #174A84;">In-Process</span>
                        </div>
                        <div class="col-md-2 ml-24 text-center p-8"
                            style="  border: #0dcaf033;  background-color: #9ad7cb;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #146e5d">Success</span>
                        </div>
                        <div class="col-md-1 p-8 text-center ml-24"
                            style=" border: #b9cb2933;  background-color: #b9cb2933;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #cd8704;">Error</span>
                        </div>
                        <div class="col-md-1 ml-24 text-center p-8"
                            style="  border: #b58888;  background-color: #b58888;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #ed0909">Fail</span>
                        </div>
                        <div class="col-md-2 ml-24 text-center p-8"
                            style="  border: #746e6e;  background-color: #746e6e;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #000000">Cancelled</span>
                        </div>
                        <div class="col-md-1 ml-24 text-center p-8"
                            style="  border: #e1b69a;  background-color: #e1b69a;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #FF6600">Killed</span>
                        </div>
                        <div class="col-md-2 ml-24 text-center p-8"
                            style="  border: #be9ed1;  background-color: #be9ed1;margin-left:10px;">
                            <span for="inputBillTo" class="status-data" style="color: #A200FF">Wrong parameter</span>
                        </div>
                    </div>
                </div>


            </div>

            <div class="row g-4 mt-10">

                <div class="col-md-8">
                    <table class="table table-striped mt-25">
                        <thead>
                            <tr>
                                <th scope="col">Sl No</th>
                                <th scope="col">File Type</th>
                                <th scope="col">File Name</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="result_filename_array !== null">
                            <tr *ngFor="let Result_files of Result_file; let i=index;">
                                <td> {{i+1}}</td>
                                <td >{{Result_files.file_type}} </td>
                                <td *ngIf="Result_files.file_name == null">-</td>
                                <td *ngIf="Result_files.file_name != null"> {{Result_files.file_name}}</td>
                                <td *ngIf="Result_files.url != null">
                                    <a class="addButton" target="_blank" mat-raised-button href="{{Result_files.url}}">
                                        View/download</a>
                                </td>
                                <td *ngIf="Result_files.url == null">
                                 -
                                </td>
                            </tr>
                            <tr *ngFor="let Php_logs of Php_log; let i=index;">
                                <td> {{i+Result_file.length+1}}</td>
                                <td>{{Php_logs.file_type}} </td>
                                <td *ngIf="Php_logs.file_name == null">-</td>
                                <td *ngIf="Php_logs.file_name != null"> {{Php_logs.file_name}}</td>
                                <td *ngIf="Php_logs.url == null">-</td>
                                <td *ngIf="Php_logs.url != null">
                                    <a class="addButton" target="_blank" mat-raised-button href="{{Php_logs.url}}">
                                        View/download</a>
                                </td>
                            </tr>
                            <tr *ngFor="let Java_logs of Java_log; let i=index;">
                                <td> {{i+1+Result_file.length+Java_log.length}}</td> 
                                  <td>{{Java_logs.file_type}} </td>

                                <td *ngIf="Java_logs.file_name == null">-</td>
                                <td *ngIf="Java_logs.file_name != null"> {{Java_logs.file_name}}</td>
                                <td *ngIf="Java_logs.url != null">
                                    <a class="addButton" target="_blank" mat-raised-button href="{{Java_logs.url}}">
                                        View/download</a>
                                </td>
                                <td *ngIf="Java_logs.url == null">
                                 -
                                </td>    
                            </tr>
                        </tbody>
                        <tbody *ngIf="result_filename_array == null">
                            <tr >
                                <td colspan="5" style="text-align: center;">
                                    <p class="nodatafound"> No Data Found... </p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <!-- <div class="row" style="margin-top:20px ;"> -->
                <!-- <mat-card-actions>

                    <div class="col-md-11"></div>
                    <div class="col-md-1 text-left">

                        <button mat-raised-button routerLink="/Orders">Cancel </button>
                    </div>
                </mat-card-actions> -->
            <!-- </div> -->

        </div>
    </mat-card-content>
</mat-card>