import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ClientsComponent } from './clients/clients.component';
import { AddClientComponent } from './clients/add-client/add-client.component';
import { LoginComponent } from './login/login.component';
import { OrdersComponent } from './orders/orders.component';
import { ApiLogsComponent } from './api-logs/api-logs.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { MyInfoComponent } from './my-info/my-info.component';
import { AppsComponent } from './apps/apps.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { authGuard } from './guard/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { EditClientComponent } from './clients/edit-client/edit-client.component';
import { MODALComponent } from './modal/modal.component';
import { AddAppsComponent } from './apps/add-apps/add-apps.component';
import { ViewOrdersComponent } from './orders/view-orders/view-orders.component';
import { ViewStatisticsComponent } from './statistics/view-statistics/view-statistics.component';
import { EditAppsComponent } from './apps/edit-apps/edit-apps.component';
import { ViewApiLogsComponent } from './api-logs/view-api-logs/view-api-logs.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
    title:"Welcome To Admin Panel",
  },
  {
    path: 'clients-edit',
    component: EditClientComponent,
    canActivate:[authGuard],
    title: 'Admin | Edit Client Data'
  },
  {
    path: 'clients',
    component: ClientsComponent,
    canActivate:[authGuard],
    title:"Admin | Clients",
  },  
  {
    path: 'add-app',
    component: AddAppsComponent
  },
  {
    path: 'Apps',
    component: AppsComponent
  },
  {
    path: 'edit-app',
    component: EditAppsComponent,
    canActivate:[authGuard],
    title: 'Admin | Edit App Data'
  },
  {
    path: 'Orders',
    component: OrdersComponent,
    canActivate:[authGuard],
    title: 'Admin | Orders'
  },
  {
    path:'order',
    component:ViewOrdersComponent,
    canActivate:[authGuard],
    title: 'Admin | Order Detail'
  },
 
  {
    path:'APILogs',
    component:ApiLogsComponent,
    canActivate:[authGuard],
    title: 'ApILog | API Logs Details'
  },
  {
    path: 'VeiwAPILogs',
    component: ViewApiLogsComponent,
    canActivate:[authGuard],
    title: 'View ApILog | View API Logs Details'
  },
  {
    path: 'Invoice',
    component: StatisticsComponent
  },
  {
    path:'View-invoice',
    component:ViewStatisticsComponent
  },
  {
    path: 'MyInformation',
    component: MyInfoComponent
  },

  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate:[authGuard],
    title:"Admin Panel | Dashboard",
  },
  {
    path: 'add-client',
    component: AddClientComponent
  },
 {
    path: 'login',
    component: LoginComponent,
    title: 'Admin | Login'
  },
  {
    path: 'logout', 
    component: LogoutComponent
  },
  {
    path: '**', 
    component: PagenotfoundComponent
  },
  {
    path : 'modal',
    component:MODALComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
