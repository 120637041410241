import { Component, EventEmitter, Output } from '@angular/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
@Output() toggleSidebarForMe:EventEmitter<any>=new EventEmitter();
 

constructor(public appdata:AppComponent){

}


ngOnInit():void{}

  toggleSidebar(){
     this.toggleSidebarForMe.emit();
  }
}
