import { NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatToolbarModule} from '@angular/material/toolbar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatSidenavModule} from '@angular/material/sidenav';
import { HeaderComponent } from './header/header.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { MatListModule} from '@angular/material/list';
import { DashboardComponent } from './dashboard/dashboard.component'
import {MatMenuModule} from '@angular/material/menu';
import { ClientsComponent } from './clients/clients.component';
import {MatBadgeModule} from '@angular/material/badge';
import {MatCardModule} from '@angular/material/card';
import {MatTableModule} from '@angular/material/table';
import { AddClientComponent } from './clients/add-client/add-client.component';
import { MatDialogModule } from '@angular/material/dialog';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import {MatInputModule} from '@angular/material/input';
import {MatRadioModule} from '@angular/material/radio';
import { LoginComponent } from './login/login.component';
import { AppsComponent } from './apps/apps.component';
import { AddAppsComponent } from './apps/add-apps/add-apps.component';
import { OrdersComponent } from './orders/orders.component';
import { ApiLogsComponent } from './api-logs/api-logs.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { MyInfoComponent } from './my-info/my-info.component';
import { PagenotfoundComponent } from './pagenotfound/pagenotfound.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AuthserviceService } from './services/authservice.service';
import { authGuard } from './guard/auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { EditClientComponent } from './clients/edit-client/edit-client.component';
import { MODALComponent } from './modal/modal.component';
import { ViewOrdersComponent } from './orders/view-orders/view-orders.component';
import { ViewStatisticsComponent } from './statistics/view-statistics/view-statistics.component';
import { MatCheckboxModule } from '@angular/material/checkbox'; 
import { MatSnackBarModule } from '@angular/material/snack-bar';
import {EditAppsComponent } from './apps/edit-apps/edit-apps.component';
import { ViewApiLogsComponent } from './api-logs/view-api-logs/view-api-logs.component'
import {MatPaginatorModule} from '@angular/material/paginator';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidenavComponent,  
    DashboardComponent,
    ClientsComponent,
    AddClientComponent,
    LoginComponent,
    AppsComponent,
    AddAppsComponent,
    OrdersComponent,
    ApiLogsComponent,
    StatisticsComponent,
    MyInfoComponent,
    PagenotfoundComponent,
    LogoutComponent,
    EditClientComponent,
    MODALComponent,
    ViewOrdersComponent,
    ViewStatisticsComponent,
    EditAppsComponent,
    ViewApiLogsComponent
 
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatToolbarModule,
    BrowserAnimationsModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatBadgeModule,
    MatCardModule,
    MatTableModule,
    MatDialogModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatPaginatorModule,  
    ToastrModule.forRoot(),
    NgbModule,
    NgxUiLoaderModule,  
    NgxUiLoaderHttpModule.forRoot({ showForeground: true })
    // ViewOrdersComponent
    
  ],
  providers: [
    AuthserviceService,
    Title,
    // ToastrService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

 }
