import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class MyInfoService { 
  dev_api_base_url ="https://dev.java.reportlab.mygenomebox.in/";
  constructor(private users:HttpClient) { }
  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '' + localStorage.getItem('user_token'),
  });
  headers1=new HttpHeaders({
    'Content-Type': 'multipart/form-data',
    'Authorization': '' + localStorage.getItem('user_token'),
  });

  fetch_my_info(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/fetch_my_info',data,{headers: this.headers, observe: "response"});
  }

  update_password(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/update_password',data,{headers: this.headers, observe: "response"});
  }
}
//  return this.users.post(this.dev_api_base_url+'v1/auth/authenticate',data,{headers: this.headers, observe: "response"});
 