import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { MODALComponent } from '../../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { ToastrService } from 'ngx-toastr';
import { UpperCasePipe } from '@angular/common';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {
  fixedflag: number | undefined;
  apieditListData: any;
  message: any;
  apieditListDataStatus: any;
  queryParams: any;
  page_size: any;
  page_index: any;

  constructor(
    private route: ActivatedRoute,
    private apicall: ApiservicesService,
    private _dialog: MatDialog,
    private previousRouteService: PreviousRouteService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  payload: any
  clientid: any;
  partnerid: any;
  processdatatype: any;
  clientdata: any;
  showflag: boolean = false;
  flag: boolean = false;
  appflag: boolean = false;
  appdetails: any;
  checkeddata: any = [
    { value: 'en', isSelected: false },
    { value: 'kr', isSelected: false },
    { value: 'cn', isSelected: false },
    { value: 'jp', isSelected: false },
  ];
  languages: any = [
    { language: "EN", value: "en", isSelected: false },
    { language: "KR", value: "kr", isSelected: false },
    { language: "CN", value: "cn", isSelected: false },
    { language: "JP", value: "jp", isSelected: false },
  ];
  languages1: any = [
    { language: "EN", value: "en", isSelected: false },
    { language: "KR", value: "kr", isSelected: false },
    { language: "CN", value: "cn", isSelected: false },
    { language: "JP", value: "jp", isSelected: false },
  ];
  // report_typee = [
  //   { name: "original", value: "original", isChecked: false },
  //   { name: "whitelabel", value: "whitelabel", isChecked: false },
  //   { name: "cobranded", value: "cobranded", isChecked: false },
  //   { name: "text", value: "text", isChecked: false },
  //   // { name: "custom", value: "custom" }
  // ];
  reportess1:any = [
    { report_type: "original", value: "original",isSelected:false },
    { report_type: "whitelabel", value: "whitelabel" ,isSelected:false},
    { report_type: "cobranded", value: "cobranded",isSelected:false },
    { report_type: "text", value: "text",isSelected:false }   
  ];
  reportess2:any = [
    { report_type: "original", value: "original",isSelected:false },
    { report_type: "whitelabel", value: "whitelabel" ,isSelected:false},
    { report_type: "cobranded", value: "cobranded",isSelected:false },
    { report_type: "text", value: "text",isSelected:false }   
  ];
  currencyy = [
    { name: "INR", value: "INR" },
    { name: "USD", value: "USD" },
  ]
  price_type = [
    { name: "Fixed", value: "Fixed" },
    { name: "Volumetric", value: "Volumetric" },
  ]
  reportetype: any[any] = []
  languagess: any[any] = []
  selectedLanguage: any = [];
  selectedReport: any = [];
  unassignedApp: any;
  unassignedAppdata: boolean = true;
  allAppDetails: any;
  language_add: any;
  report_add:any;
  previousurl: any;
  fixed_price: any
  vol_price1: any
  vol_price2: any
  vol_price3: any
  updatereprt_type:any
  pricetypeselected:any

  ngOnInit(): void {
    // if(this.previousurl == ""){
    this.previousurl = "#/clients";
    // }else{
    //   this.previousurl = this.previousRouteService.getPreviousUrl();
    // }

    this.route.queryParams
      .subscribe(params => {        
        this.clientid = params['clientid'];
        this.partnerid = params['partnerid'];     
        
        this.queryParams = history.state;
        this.page_size=this.queryParams.page_size;
        this.page_index=this.queryParams.page_index;       
        if (this.partnerid === "null") {
          this.partnerid = null;
        }
      }
      );
    this.payload = {
      "page_index":this.page_index,
      "page_size":this.page_size,
    }
    this.processdatatype = this.route.snapshot.fragment;
    this.apicall.getClientPartnerDetails(this.payload).subscribe(params => {
      this.clientdata = params.body;
      this.clientdata = JSON.parse(JSON.stringify(this.clientdata));
      this.clientdata = this.clientdata["result"]['data'];
      

      for (let i = 0; i < this.clientdata.length; i++) {
        if (this.partnerid === undefined) {
          partnerid = null
        } else {
          partnerid = this.partnerid
        }
        
        if (this.clientdata[i]['client_id'] === this.clientid && this.clientdata[i]['partner_id'] === partnerid) {
          if(this.clientdata[i]['appList']){
            if(this.clientdata[i]['appList'].length == 0){

            }else{
              for (let j = 0; j < this.clientdata[i]['appList'].length; j++) {
                
                const app_id = this.clientdata[i]['appList'][j]["ass_app_id"]
                console.log(app_id);
                
                const app_namee = this.clientdata[i]['appList'][j]["app_name"]
                this.language_add = this.clientdata[i]['appList'][j]["report_language"]
                this.report_add = this.clientdata[i]['appList'][j]["report_type"]
                let langdata={app_name:app_namee}
                this.apicall.fetch_report_language(langdata).subscribe(params => {
                  let client = params.body;
                  let clientdetails = JSON.parse(JSON.stringify(client));
                  let clientdetailsreport_type = clientdetails["result"]["report_type"];
                
                  
                  clientdetails = clientdetails["result"]["report_lang"];
                  
                  this.languages1[app_id]=[]
                  
                  this.reportess2[app_id]=[]
                  for(let iii =0; iii <clientdetails.length; iii++){
                      if(this.clientdata[i]['appList'][j]["report_language"].indexOf(clientdetails[iii]) !== -1){
                        this.languages1[app_id].push({ language: clientdetails[iii], value: clientdetails[iii], isSelected: true, appid: app_id})
                      }
                      if(this.clientdata[i]['appList'][j]["report_language"].indexOf(clientdetails[iii]) === -1){
                        this.languages1[app_id].push({ language: clientdetails[iii], value: clientdetails[iii], isSelected: false, appid: app_id})
                      }
                      this.languagess[app_id] =this.languages1[app_id]
                  }
              
                  if(clientdetailsreport_type !=null){
                  for(let s =0; s <clientdetailsreport_type.length; s++){
              
                    
                    if(this.clientdata[i]['appList'][j]["report_type"].indexOf(clientdetailsreport_type[s]) !== -1){
                      this.reportess2[app_id].push({ report_type: clientdetailsreport_type[s], value: clientdetailsreport_type[s], isSelected: true, appid: app_id})
                      console.log("hhh",clientdetailsreport_type,app_id);
                    }
                    if(this.clientdata[i]['appList'][j]["report_type"].indexOf(clientdetailsreport_type[s]) === -1){
                      this.reportess2[app_id].push({ report_type: clientdetailsreport_type[s], value: clientdetailsreport_type[s], isSelected: false, appid: app_id})
                    }
                    this.reportetype[app_id] =this.reportess2[app_id]       
       
                    
                  }
                } 
                });
              }
            }
          }
        }

      }


    },
      (err) => {
        alert(err.status);
      });

    let partnerid;
    if (this.partnerid === "undefined" || this.partnerid === undefined) {
      partnerid = ""
    } else {
      partnerid = this.partnerid
    }


    const nt_assgn_details = { client_id: this.clientid, partner_id: partnerid };
    this.apicall.getNotAssignedApp(nt_assgn_details).subscribe(param => {
      this.appdetails = param.body;
      this.appdetails = JSON.parse(JSON.stringify(this.appdetails));
      this.appdetails = this.appdetails['result'];
    },
      (err) => {
        if (err.status === 404) {
          this.unassignedApp = err.error["message"];
          this.unassignedAppdata = false;
        }
      });

    let payload = {
      "page_index":this.page_index,
      "page_size":this.page_size,
    }
    this.apicall.getAllApp(payload).subscribe(param => {
      this.allAppDetails = param.body;
      this.allAppDetails = JSON.parse(JSON.stringify(this.allAppDetails));    
      if(this.allAppDetails["result"]){
        this.allAppDetails = this.allAppDetails['result']['data'];
      }
    });
  }

  openClientApp(data: any) {
    this.showflag = data;
  }

  newAppOpen() {
    if (this.appflag === false) {
      this.appflag = true;
    } else {
      this.appflag = false;
    }

  }

  getLanguages(paraname: any, event: any) {
    if (event.checked) {
      this.selectedLanguage.push(paraname);
    } else {
      let index = this.selectedLanguage.indexOf(paraname)
      this.selectedLanguage.splice(index, 1);
    }   console.log("77aaaa",this.selectedLanguage);
  
  }

  getReportType(paraname: any, event: any) {
    if (event.checked) {
      this.selectedReport.push(paraname);
    } else {
      let index = this.selectedReport.indexOf(paraname)
      this.selectedReport.splice(index, 1);
    }
 
  }
  assignApp(data: any) {
    let fixed_price
    let vol_price1
    let vol_price2
    let vol_price3
    if (data.price == "Volumetric") {
      fixed_price = ""
      vol_price1 = data.volumetric1_value
      vol_price2 = data.volumetric2_value
      vol_price3 = data.volumetric3_value
    } else {
      fixed_price = data.fixed_price
      vol_price1 = ""
      vol_price2 = ""
      vol_price3 = ""

    }
    const dataa = { client_id: this.clientid, partner_id: this.partnerid, app_name: data.selectedapp, report_type_array: this.selectedReport, report_language_array: this.selectedLanguage, currency_type: data.currency, price_type: data.price, fixed_price: fixed_price, vol_price1: vol_price1, vol_price2: vol_price2, vol_price3: vol_price3 };



    this.apicall.assignApps(dataa).subscribe(params => {

      this.apieditListData = params.body;
      this.message = this.apieditListData["message"];
      this.apieditListDataStatus = this.apieditListData["status"];      
      // if (this.apieditListData["result"]) {
        if (this.apieditListDataStatus == true) {
          this.toastr.success(this.message);
         setTimeout(function () { window.location.reload(); }, 2000);
        }
      // }
      else if (this.apieditListData == 202)  {
        this.toastr.warning(this.message);        
      }else if(this.apieditListDataStatus == 402){ this.toastr.error("Data Cannot be Blank !!"); }
      else{
        this.toastr.error(this.message);       
      }
    })
  }

  openConfirmationDialog(client_id: any, partner_id: any, app_id: any) {
    const dialogRef = this._dialog.open(MODALComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.delete = "Unassign App"
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to Unassign App?"
    dialogRef.componentInstance.button_label = "Unassign App";
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let dataa: any;
        if (partner_id === "null" || partner_id === null) {
          dataa = { client_id: client_id, app_name: app_id }
        } else {
          dataa = { client_id: client_id, partner_id: partner_id, app_name: app_id }
        }
        this.apicall.unAssignApp(dataa).subscribe(params => {
          setTimeout(function(){ location.reload(); }, 2000)
        });
      }
      const dialogRef = null;
    });
  }

  openValue(flag: any, fixed_price: any, vol_price1: any, vol_price2: any, vol_price3: any, app_id: any, lang:any,type:any) {
    
    this.selectedLanguage=[];
    for(let iff=0; iff < lang.length; iff++){
      if (lang[iff]["isSelected"] ===true) {
        this.selectedLanguage.push(lang[iff]["value"]);
      }
    }    
    this.selectedReport=[];
    for(let iffs=0; iffs < type.length; iffs++){
      if (type[iffs]["isSelected"] ===true) {
        this.selectedReport.push(type[iffs]["value"]);
      }
    }   

    this.updatereprt_type = undefined
    this.pricetypeselected = flag

    this.fixed_price = fixed_price
    this.vol_price1 = vol_price1
    this.vol_price2 = vol_price2
    this.vol_price3 = vol_price3
    if (flag === "Fixed") {
      flag = app_id + 1
    }
    if (flag === "Volumetric") {
      flag = app_id + 2
    }
    this.fixedflag = flag;
   
    
  }

  goto(url: any) {
    window.location.href = url

  }

  getcurrency(event:any){
  
    
      this.selectedLanguage=[];
      this.selectedReport=[];
      let appname =event.target.value
      let data={app_name:appname}
      this.apicall.fetch_report_language(data).subscribe(params => {
        let client = params.body;
        let clientdetails = JSON.parse(JSON.stringify(client));
        let clientdetailsreport_type = clientdetails["result"]["report_type"];
        
        clientdetails = clientdetails["result"]["report_lang"];
       
        this.languages=[];
        for(let i =0; i <clientdetails.length; i++){
          this.languages[i] = { language: clientdetails[i], value: clientdetails[i], isSelected: false}       
        }
         this.reportess1=[];
         if(clientdetailsreport_type!=null){
          for(let m =0; m <clientdetailsreport_type.length; m++){
            this.reportess1[m] = { report_type: clientdetailsreport_type[m], value: clientdetailsreport_type[m], isSelected: false}          
           } 
          } 
      });
  }

  updatereport_type(event:any){
    let updtval = event.target.value;
    this.updatereprt_type = updtval
    
  }

  update_assign_app(data: any) {
    
    if (this.pricetypeselected == "Volumetric") {
      if(data.volprice1 === null || data.volprice2 === null || data.volprice3 === null || data.volprice1 === "" || data.volprice2 === "" || data.volprice3 === ""){
        this.toastr.error("Check Required Field");
        return;
      }
    } else {
      if(data.fixedprice === null || data.fixedprice === ""){
        this.toastr.error("Check Required Field");
        return;
      }

    }

    let reporttyp
    if(this.updatereprt_type == undefined){
      reporttyp = data.report_type
    }else{
      reporttyp = this.updatereprt_type
    }

    let parnerid
    if(this.partnerid === undefined){
      parnerid=null
    }else{
      parnerid = this.partnerid
    }

    let dataa ={ 
      "client_id":this.clientid,
      "client_name":"client2",
      "partner_id":parnerid,
      "partner_name":"partner2",
      "app_name":data.selectedapp,
      "report_type":reporttyp,
      "report_language_array":this.selectedLanguage,  
      "report_type_array":this.selectedReport,  
      "fixed_price":data.fixedprice,
      "price_type":this.pricetypeselected,
      "vol_price1": data.volprice1,
      "vol_price2": data.volprice2,
      "vol_price3": data.volprice3,
      "currency_type":data.currency
    } 
    // console.log("787878",data);
    
    this.apicall.update_assigned_app(dataa).subscribe(params=>{
      let updtsts
      updtsts = params.body;
      let updtt = JSON.parse(JSON.stringify(updtsts));
      this.message = updtt["message"];
      if (updtt["result"]) {
        if (updtt.status == true) {
          let clientid = updtt.result['client_id'];
          let partnerid = updtt.result['partner_id'];
          if (partnerid === "") {
            partnerid = "null";
          }
          this.toastr.success("Client / Partner Updated Successfully");
          setTimeout(function () { window.location.reload(); }, 2000);
        }
      }
      else if (updtt.status == 600) {
        this.toastr.warning(this.message);
      }else if (updtt.status == 402) {
        this.toastr.error("Data Cannot be Blank !!");
      } else {
        this.toastr.error(this.message);
      }
    })
  }


}
