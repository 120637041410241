import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddClientComponent } from './add-client/add-client.component';
import { Router, RouterLink } from '@angular/router';
import { ApiservicesService } from '../services/apiservices.service';
import { AuthserviceService } from '../services/authservice.service';
import { MODALComponent } from '../modal/modal.component';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { HttpHeaders } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
/**
 * @title Basic use of `<table mat-table>`
 */
@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['../../styles.css', './clients.component.css'],
})
export class ClientsComponent implements OnInit {
  pageInfo: any;
  clientdata: any;
  datalength: any;
  payload: any;
  pageEvent!: PageEvent;
  apideleteListData: any;
  message: any;
  status:any
  constructor(
    private router: Router,
    private _dialog: MatDialog,
    private apicall: ApiservicesService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0

    }
    this.handlePageEvent(this.pageInfo)
  }

  handlePageEvent(e: PageEvent) {
    this.pageInfo.pageEvent = e;
    this.pageInfo.totalElements = e.length;
    this.pageInfo.pageSize = e.pageSize;
    this.pageInfo.pageIndex = e.pageIndex;
    this.clientList();
  }


  clientList() {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': '' + localStorage.getItem('user_token'),
    });

    let payload = {
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
    }
    this.apicall.getClientPartnerDetails(payload, headers).subscribe(params => {
      this.clientdata = params.body;
      this.clientdata = JSON.parse(JSON.stringify(this.clientdata));
      if(this.clientdata["status"] === "202"){
        this.datalength = 0;
      }else{
        this.pageInfo.totalElements = this.clientdata["result"].totalElements;
        this.clientdata = this.clientdata["result"]['data'];
  
        this.datalength = this.clientdata.length;
      }
     
    },
      (err) => {
        if (err.status === 404) {
          this.datalength = 0;
        } else {

        }
      });
  }
  addClient(): void {
    this.router.navigate(['/add-client']);
  }

  goClient(clientid: any, partnerid: any) {
    let payload = {
      page_size: this.pageInfo.pageSize,
      page_index: this.pageInfo.pageIndex
    };    
    this.router.navigate(
      ['/clients-edit'],
      { queryParams: { clientid: clientid, partnerid: partnerid }, fragment: 'editdata',state: payload }
    );
  }


  openConfirmationDialog(client_id: any, partner_id: any) {
    const dialogRef = this._dialog.open(MODALComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.delete = "Delete Clients";
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?";
    dialogRef.componentInstance.button_label = " Yes, Delete it";

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let dataa: any;
        if (partner_id === "null" || partner_id === null) {
          dataa = { client_id: client_id }
        } else {
          dataa = { client_id: client_id, partner_id: partner_id }
        }
        this.apicall.removeClientorpartner(dataa).subscribe(params => {
          this.apideleteListData = params.body;
          this.message = this.apideleteListData["message"];
          this.apideleteListData = this.apideleteListData["status"];
          if (this.apideleteListData == true) {
            this.toastr.success(this.message);
            setTimeout(function () { window.location.reload(); }, 2000);
          }
          else {
            this.toastr.error(this.message);
            setTimeout(function () { window.location.reload(); }, 2000);
          }
        });
      }
      const dialogRef = null;
    });
  }



}