import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { formatDate } from '@angular/common';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
@Component({
  selector: 'app-edit-apps',
  templateUrl: './edit-apps.component.html',
  styleUrls: ['../../../styles.css', './edit-apps.component.css']
})
export class EditAppsComponent implements OnInit {
  editappForm: FormGroup | any;
  app_id: any;
  payload: any;
  appList: any;
  app_description: any;
  app_name: any;
  app_status: any;
  co_developer: any;
  date_of_creation: any;
  process_time: any;
  report_lang: any;
  
  app_icon: any;
  id: any;
  hours: any;
  minutes: any;
  data: any;
  addprocess_time: any;
  imagefile: any
  languagess:any = [
    { language: "EN", value:"en",isSelected:false },
    { language: "KR", value:"kr",isSelected:false },
    { language: "CN", value:"cn",isSelected:false },
    { language: "JP", value:"jp",isSelected:false }
  ];
  reportess:any = [
    { report_type: "original", value: "original",isSelected:false },
    { report_type: "whitelabel", value: "whitelabel" ,isSelected:false},
    { report_type: "cobranded", value: "cobranded",isSelected:false },
    { report_type: "text", value: "text",isSelected:false }   
  ];
  
  
  apiListData: any;
  message: any;
  report_type: any;
  constructor(private router:Router,private apicall: ApiservicesService, private _formBuilder: FormBuilder, private route: ActivatedRoute, private toastr: ToastrService) { }
  languages = [
    { language: "en" },
    { language: "kr" },
    { language: "cn" },
    { language: "jp" } 
  ];
  selectedLanguage: any = [];
  reports = [
    { report_type: "original" },
    { report_type: "whitelabel" },
    { report_type: "cobranded" },
    { report_type: "text" }   
  ];
  selectedReport: any = [];
 
  ngOnInit(): void {    
    console.log(this.reportess);
    console.log(this.languagess);
    this.editappForm = this._formBuilder.group({
      app_id: [''],
      app_name: ['', [Validators.required]],
      date_of_creation:[''],
      codeveloper:[''],
      hours:[''],
      minutes: [''],
      app_description: [''],
      appiconfile: []

    });
    this.route.queryParams
      .subscribe(params => {
        this.app_id = params["appid"];
      })
    this.specific_app_id();
    
  }
  getLanguages(paraname: any, event: any) {
    if (event.checked) {
      this.selectedLanguage.push(paraname);
    } else {
      let index = this.selectedLanguage.indexOf(paraname)
      this.selectedLanguage.splice(index, 1);
    }

    console.log("Selected",this.selectedLanguage)
  }
 
  getReports(paraname: any, event: any) {
    if (event.checked) {
      this.selectedReport.push(paraname);
    } else {
      let index = this.selectedReport.indexOf(paraname)
      this.selectedReport.splice(index, 1);
    }

    console.log("Selected",this.selectedReport)
  }

  specific_app_id() {
    this.payload = {
      "app_id": this.app_id,
    }
    this.apicall.specific_app_id(this.payload).subscribe(params => {
      this.appList = params.body;
      this.appList = JSON.parse(JSON.stringify(this.appList));
      this.appList = this.appList["result"];
      this.selectedLanguage=[];

      for(let k=0; k < this.languagess.length; k++){
        this.languagess.map((elem:any,id:any) => {
          if (elem.value == this.appList.report_lang[k]){
            this.selectedLanguage.push(this.appList.report_lang[id])
            this.languagess[id] = { language: this.appList.report_lang[id], value:this.appList.report_lang[id],isSelected:true };
          }                      
        });           
      }
       for(let m=0; m < this.reportess.length; m++){
        this.reportess.map((elem:any,id:any) => {
          if (elem.value == this.appList.report_type[m]){
            this.selectedReport.push(this.appList.report_type[id])
            this.reportess[id] = { language: this.appList.report_type[id], value:this.appList.report_type[id],isSelected:true };
          }                      
        });           
      }
     

      this.editappForm.controls['app_name'].setValue(this.appList.app_name);
      this.editappForm.controls['app_id'].setValue(this.appList.app_id);
      this.editappForm.controls['app_description'].setValue(this.appList.app_description);
      this.editappForm.controls['codeveloper'].setValue(this.appList.codeveloper);
      this.date_of_creation = this.appList.date_of_creation;
      this.editappForm.controls['date_of_creation'].setValue(formatDate(this.date_of_creation, 'yyyy-MM-dd hh:mm:ss', 'en_US')),
      this.process_time = this.appList.process_time;
      if (this.process_time != null) {
        var a = this.process_time.split(":");
        this.editappForm.controls['hours'].setValue(a[0]);
        this.editappForm.controls['minutes'].setValue(a[1]);
      }
      this.report_lang = this.appList.report_lang;
      this.report_type = this.appList.report_type;
      this.app_icon = this.appList.app_icon;
    },);
  }

  getimagedata(event: any) {


    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.imagefile = fileList[0];

        const reader = new FileReader();
        reader.onload = e => this.app_icon = reader.result;

        reader.readAsDataURL(this.imagefile);
    }  
  }

  editAppsdata() {
    let process_time = ('0' + this.editappForm.value.hours).slice(-2) + ":" + ('0' + this.editappForm.value.minutes).slice(-2)
    let formData: FormData = new FormData();
    if (this.editappForm.value.appiconfile != null) {
      formData.append('app_icon', this.imagefile, this.imagefile.name);
    }
    formData.append('app_id', this.editappForm.value.app_id);
    formData.append('app_name', this.editappForm.value.app_name);
    formData.append('codeveloper', this.editappForm.value.codeveloper);
    formData.append('date_of_creation', formatDate(this.editappForm.value.date_of_creation, 'dd-MMM-yyyy 00:00:00', 'en_US'));
    formData.append('app_description', this.editappForm.value.app_description);
    formData.append('report_lang', this.selectedLanguage);
    formData.append('report_type', this.selectedReport);
    formData.append('process_time', process_time);
    this.apicall.update_app(formData).subscribe(params => {
      this.apiListData = params.body;      
      this.message=this.apiListData["message"];     
      this.apiListData = this.apiListData ["status"];
      if(this.apiListData == true){
        this.toastr.success(this.message);
        this.router.navigate(['/Apps']);
      }
      else{
        this.toastr.error(this.message);
      }
    }
    );
  }
}


