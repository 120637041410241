import { Component, OnInit } from '@angular/core';
import { ApiservicesService } from '../services/apiservices.service';
import { Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['../../styles.css', './orders.component.css']
})
export class OrdersComponent implements OnInit {
  addForm: FormGroup | any;
  orderdata: any;
  clientdata: any;
  datalength: any;
  orderviewdata: any;
  customdateFlag: boolean = false;
  partnerdata: any;
  appdata: any;
  end_dates: any;
  start_dates: any;
  errorshow: boolean = false;
  errorfilterappshow: any;
  payload: any;
  errorclientshow: boolean = false;
  errorappshow: boolean = false;
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  pageEvent!: PageEvent;
  pageInfo: any;
  orderviewdatastatus: any;

  constructor(private apicall: ApiservicesService,
    private _formBuilder: FormBuilder,
    private router: Router) { }

  ngOnInit(): void {
    this.addForm = this._formBuilder.group({
      client_id: ["", []],
      partner_id: ["", []],
      duration: ["", []],
      app_id: ["", []],
      status_value: [null, []],
      start_date: [null, []],
      end_date: [null, []],
      order_id: [null, []]
    });

    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0

    }
    this.handlePageEvent(this.pageInfo)
    this.end_dates = null;
    this.start_dates = null;

    this.clientWithName();
    this.fetchApp();


  }

  handlePageEvent(e: PageEvent) {
    this.pageInfo.pageEvent = e;
    this.pageInfo.totalElements = e.length;
    this.pageInfo.pageSize = e.pageSize;
    this.pageInfo.pageIndex = e.pageIndex;
    this.appFilter()
  }

  viewOrder(order_id: any) {
    this.router.navigate(['/order'],
      { queryParams: { orderid: order_id }, fragment: 'vieworder' }
    )

  }

  customDate(value: any) {
    if (value == "custom") {
      this.customdateFlag = true;
    } else {
      this.customdateFlag = false;
    }
  }

  clientWithName() {
    this.clientdata = null;
    this.apicall.clientWithName().subscribe(params => {
      this.clientdata = params.body;
      this.clientdata = JSON.parse(JSON.stringify(this.clientdata));
      this.clientdata = this.clientdata["result"];
    },
      (err) => {
        if (err.status === 404) {
          if (err["error"]["status"] == 'false') {
            this.errorclientshow = true;
            this.clientdata = [];
          }
        }
      });
  }

  partnerWithClient() {
    this.partnerdata = null;
    const data = { client_id: this.addForm.value.client_id.client_id };
    this.errorshow = false;
    this.apicall.partnerWithClient(data).subscribe(params => {
      this.partnerdata = params.body;
      this.partnerdata = JSON.parse(JSON.stringify(this.partnerdata));
      this.partnerdata = this.partnerdata["result"];
      this.errorappshow = false;
    },
      (err) => {
        if (err.status === 404) {
          if (err["error"]["status"] == 'false') {
            this.errorshow = true;
            this.partnerdata = [];
          }
        }
      });

  }

  fetchAppNameForClient() {
    this.appdata = null;
    const data = { clientId: this.addForm.value.client_id.client_id, partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
      this.errorappshow = false;
    },
      (err) => {
        if (err.status === 404) {
          if (err["error"]["status"] == 'false') {
            this.errorappshow = true;
            this.appdata = [];
          }
        }
      });
  }

  fetchAppName() {
    this.appdata = null;
    const data = { clientId: this.addForm.value.client_id.client_id, partnerId: this.addForm.value.partner_id.partner_id };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
      this.errorappshow = false;
    },
      (err) => {
        if (err.status === 404) {
          if (err["error"]["status"] == 'false') {
            this.errorappshow = true;
            this.appdata = [];
          }
        }
      });
  }

  fetchApp() {
    this.appdata = null;
    const data = { clientId: '', partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    },
      (err) => {
        if (err.status === 404) {
          if (err["error"]["status"] == 'false') {
            this.errorappshow = true;
            this.appdata = [];
          }
        }
      });
  }

  orderRefreshData() {
    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0
    }
    this.handlePageEvent(this.pageInfo)
  }

  appFilter() {
    let today_date = new Date();
    let today_date1 = new Date();
    if (this.addForm.value.duration == "last_day") {//last 30days
      this.start_dates = formatDate(today_date.setDate(today_date.getDate() - 30), 'dd-MMM-yyyy 00:00:00', 'en-US');
      this.end_dates = formatDate(today_date1.setDate(today_date1.getDate()), 'dd-MMM-yyyy 00:00:00', 'en-US');
    } else if (this.addForm.value.duration == "one_year") {//one year
      this.start_dates = formatDate(today_date.setDate(today_date.getDate() - 365), 'dd-MMM-yyyy 00:00:00', 'en-US');
      this.end_dates = formatDate(today_date1.setDate(today_date1.getDate() + 1), 'dd-MMM-yyyy 00:00:00', 'en-US');
    } else if (this.addForm.value.duration == "custom") {//custom date    
      this.start_dates = formatDate(this.addForm.value.start_date, 'dd-MMM-yyyy 00:00:00', 'en_US');
      this.end_dates = formatDate(this.addForm.value.end_date, 'dd-MMM-yyyy 00:00:00', 'en_US');
    } else if (this.addForm.value.duration == "this_month") { //this month    
      var firstDay = new Date(today_date.getFullYear(), today_date.getMonth(), 1);
      this.start_dates = formatDate(firstDay, 'dd-MMM-yyyy 00:00:00', 'en_US');
      this.end_dates = formatDate(today_date, 'dd-MMM-yyyy 00:00:00', 'en_US');
    }
    this.payload = {
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
      "start_date": this.start_dates,
      "end_date": this.end_dates,
      "client_id": this.addForm.value.client_id.client_id,
      "partner_id": this.addForm.value.partner_id.partner_id,
      "duration": this.addForm.value.duration,
      "app_id": this.addForm.value.app_id.app_id,
      "order_status": this.addForm.value.status_value,
      "order_id": this.addForm.value.order_id
    }
    this.apicall.order_list_fliter(this.payload).subscribe(params => {
      this.orderviewdata = params.body;
      this.orderviewdatastatus = this.orderviewdata["status"];
      this.orderviewdata = JSON.parse(JSON.stringify(this.orderviewdata));
      if (this.orderviewdata["result"]) {
        this.pageInfo.totalElements = this.orderviewdata["result"].totalElements;
        this.orderviewdata = this.orderviewdata["result"]['data'];
      }
      if (this.orderviewdatastatus === true) {
        this.errorfilterappshow = 0;
      }
      else {
        this.errorfilterappshow = 1;
        this.orderviewdata = [];
      }
    },
      (err) => {
        if (err.status == 0) {
          this.errorfilterappshow = 1;
          this.orderviewdata = [];
        }
      },);
  }
  appListFilter() {
    this.payload = {
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
      "start_date": null,
      "end_date": null,
      "order_status": null,
      "order_id": null
    }
    // console.log("95254",this.payload);

    this.apicall.order_list_fliter(this.payload).subscribe(params => {
      this.orderviewdata = params.body;
      this.orderviewdata = JSON.parse(JSON.stringify(this.orderviewdata));
      this.pageInfo.totalElements = this.orderviewdata["result"].totalElements;
      this.orderviewdata = this.orderviewdata["result"]['data'];
      this.errorfilterappshow = false;
    },
      (err) => {
        if (err.status == 0) {
          this.errorfilterappshow = true;
          this.orderviewdata = [];

        }
      },);
  }
}


