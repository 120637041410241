<body>
    <div class="vh-100 d-flex justify-content-center align-items-center">
        <div class="container">
            <div class="row d-flex justify-content-center">
                <div class="col-12 col-md-8 col-lg-6">
                    <div class="card bg-white">
                        <span class="label" style="       background-color: #174A84;   color: white;  text-align: center;">ADMIN LOGIN</span>
                        <div class="card-body p-5 ">
                            <form #loginForm="ngForm" (ngSubmit)="getLoginDetails(loginForm.value)">
                                <div class="row g-4">
                                    <div class="col-md-4">
                                        <label for="inputBillTo" class="form-label">User Name :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <input type="text" class="form-control" required #username="ngModel" ngModel  name="username" [class.is-invalid] ="username.invalid && username.touched" list="clientList" placeholder="Enter User Name">
                                        <div *ngIf="username.errors &&(username.invalid && username.touched)">
                                            <small class="text-danger" *ngIf="username.errors"> User Name Is Required </small>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pt-5">
                                    <div class="col-md-4">
                                        <label for="inputBillTo" class="form-label">Password :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <input type="password" class="form-control" required #password="ngModel" ngModel name="password" [class.is-invalid] ="password.invalid && password.touched" id="password" list="clientList" placeholder="Enter Partner Name">
                                        <div *ngIf="password.errors &&(password.invalid && password.touched)">
                                            <small class="text-danger" *ngIf="password.errors"> Password Is Required </small>
                                        </div>
                                    </div>
                                </div>
                                
                            
                                <div class="row pt-5">     
                                    <div class="col-md-12">
                                         <small class="text-danger"> {{ error }}</small>
                                    </div>                               
                                    <div class="col-md-12 text-center" *ngIf="username.invalid && password.invalid; then disabled; else nothing"> </div>
                                        <ng-template #disabled>
                                            <button mat-raised-button  class="addButton" disabled style="margin-right: 10px;  width: 100%!important;min-width: unset!important;">LOGIN </button>
                                        </ng-template>
                                        <ng-template #nothing>
                                            <button mat-raised-button  class="addButton" style="margin-right: 10px;  width: 100%!important;min-width: unset!important;">LOGIN </button>
                                        </ng-template>
                                </div>                               
                            </form>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>


</body>

<!-- <h2 class="fw-bold mb-2 text-uppercase ">
    ADMIN LOGIN
  </h2>
  <p class=" mb-5">Please enter your login and password!</p>
  <div class="mb-3">
    <label for="email" class="form-label ">User Name</label>
    <input type="text" required class="form-control" id="email" placeholder="Enter User Name">                    
    
  </div>
  <div class="mb-3">
    <label for="password" class="form-label ">Password</label>
    <input type="password" id="password" placeholder="*******">
    
    
  </div>
  <div class="d-grid">
    <button class="btn btn-outline-dark"type="submit">Login</button>
  </div> -->