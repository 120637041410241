import { Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiservicesService } from 'src/app/services/apiservices.service';
@Component({
  selector: 'app-add-client',
  templateUrl: './add-client.component.html',
  styleUrls: ['./add-client.component.css']
})
export class AddClientComponent {
  flag: boolean = false;
  appflag: boolean = false;
  partner_error: boolean = false;
  client_data: any;
  appdetails: any;
  message: any;
  client_namee:any ="";
  currencypartnerdata:any=false
  @ViewChild('content') content!: ElementRef;
  @ViewChild('clntt') clntt!: ElementRef;
  clientdatasearch:any
  clntcurrency:any
  disabl:boolean=false

  clientcurrencyy: any[any] = [
    { currency: "INR", value: "INR", isSelected: false },
    { currency: "USD", value: "USD", isSelected: false },
    { currency: "KRW", value: "KRW", isSelected: false },
  ];

  clientdata: any = [

  ]

  constructor(private _fb: FormBuilder, private router: Router, private apicall: ApiservicesService, private toastr: ToastrService, private eRef: ElementRef) {
    let payload = {
      "page_index": 0,
      "page_size": 10,
    }
    this.apicall.getAllApp(payload).subscribe(param => {
      this.appdetails = param.body;
      this.appdetails = JSON.parse(JSON.stringify(this.appdetails));
      this.appdetails = this.appdetails['result'];
    });

  }


  billto() {
    this.flag = true;

  }
  newAppOpen() {
    this.appflag = true;
  }


  getFormdata(data: any) {
    const client_name = data.client_name;
    const partner_name = data.partner_name;
    if (partner_name.length !== 0) {
      if (!data.bill_to) {
        this.partner_error = true;
      }
    }
    if ((partner_name.length !== 0 && data.bill_to.length !== 0) || partner_name.length === 0) {
      const bill_to = data.bill_to;
      let partnercurrency
      if(bill_to == "client"){
        partnercurrency = null
      }
      if(bill_to == "partner"){
        partnercurrency = data.partnercurrency
      }

      let clientcurrency
      if(data.clientcurrency ==""){
        clientcurrency =null
      }else{
        clientcurrency = data.clientcurrency
      }

      const datata = { client_name: client_name, partner_name: partner_name, bill_to: bill_to, client_currency_type:data.clientcurrency, partner_currency_type:partnercurrency }
      this.apicall.addClientPartner(datata).subscribe(params => {
        this.client_data = params.body;
        this.client_data = JSON.parse(JSON.stringify(this.client_data));
        this.message = this.client_data["message"];
        if (this.client_data["result"]) {
          let payload = {
            page_size:10,
            page_index: 0
          }; 
          if (this.client_data.status == true) {
            let clientid = this.client_data.result['client_id'];
            let partnerid = this.client_data.result['partner_id'];
            if (partnerid === "") {
              partnerid = "null";
            }
            this.toastr.success("New client/partner added to the MyGenomeBox India report lab.");
            this.router.navigate(
              ['/clients-edit'],
              { queryParams: { clientid: clientid, partnerid: partnerid }, fragment: 'editdata',state: payload }
            );
          }
        }
        else if (this.client_data.status == 600) {
          this.toastr.warning(this.message);
        } else {
          this.toastr.error(this.message);
        }

      })
    }
  }


  showcurrency(event:any){
    let currencyshowdata = event.target.value
    if(currencyshowdata == "client"){
      this.currencypartnerdata=false
    }

    if(currencyshowdata == "partner"){
      this.currencypartnerdata=true
    }
  }

  
  
  filterFunction(event:any){
    let searchval = event.target.value
    let datea = {client_name_search:searchval}
    this.clientdata=[]
    this.apicall.fetch_client_name(datea).subscribe(params=>{
        
        this.clientdatasearch = params.body;
        this.clientdatasearch = JSON.parse(JSON.stringify(this.clientdatasearch));
      
        for(let j=0;j < this.clientdatasearch["result"].length; j++){
          this.clientdata[j] = { name: this.clientdatasearch["result"][j]}
        }
        this.content.nativeElement.style.display = 'block';
    },
    (err) => {
        this.clientdata[0] = { name: "No Data Found"}
        this.content.nativeElement.style.display = 'block';
    })

    
  }

  hidefilter(){
    this.content.nativeElement.style.display = 'none';
  }

  setData(clientname:any){
    this.hidefilter()
    if(clientname != "No Data Found"){
      this.client_namee = clientname;

      this.apicall.clientWithName().subscribe(params=>{
        let clientdataa = params.body;
        let aclientdataa = JSON.parse(JSON.stringify(clientdataa));
        aclientdataa = aclientdataa["result"];
       
        for(let o=0; o < aclientdataa.length; o++){
           if(aclientdataa[o]["client_name"] == clientname){
              let currency = aclientdataa[o]["client_currency_type"]
              this.clntcurrency = currency;
              this.disabl=true
           }
        }
      })
    }
  }
  
}
