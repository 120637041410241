<mat-card class="example-card">
    <mat-card-header>
        <span class="label">Orders</span>
        <span class="example-spacer"></span>
    </mat-card-header>
    <div class="breakline"></div>
    <mat-card-content>
        <div class="example-container mt-10">
            <form id="addForm" name="addForm" [formGroup]="addForm" novalidate>
                <div class="row g-3">
                    <div class="col-md-5 text-left">
                        <input type="text" class="form-control" placeholder="Search by Order ID"
                            formControlName="order_id">
                    </div>
                    <!-- <div class="col-md-1 text-left" style="margin-left: -66px;">
                        <button id="search-button" type="button" class="btn btn-primary"
                            style="height: 38px !important;" disabled>
                            <mat-icon>search</mat-icon>
                        </button>
                    </div> -->
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="client_id"
                            (change)="partnerWithClient(); fetchAppNameForClient();">
                            <option Value="" [disabled]="true">Select Client</option>
                            <option *ngIf="errorclientshow == true" disabled>NO Data Found</option>
                            <option *ngFor="let client of clientdata" [ngValue]="client">{{client.client_name}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="partner_id" (change)="fetchAppName();">
                            <option Value="" [disabled]="true">Select Partner</option>
                            <option *ngIf="errorshow == true" [ngValue]="" disabled>NO Data Found</option>
                            <option *ngFor="let partner of partnerdata" [ngValue]="partner">{{partner.partner_name}}
                            </option>
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="app_id">
                            <option Value="" [disabled]="true">Select App</option>
                            <option *ngIf="errorappshow == true" disabled>NO Data Found</option>
                            <option *ngFor="let app of appdata" [ngValue]="app">{{app.app_name}}</option>
                        </select>
                    </div>
                </div>
                <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <select class="form-control" (change)="customDate(duration.value)" #duration
                            formControlName="duration">
                            <option Value="" [disabled]="true">Duration</option>
                            <option value="this_month">This Month</option>
                            <option value="last_day">Last 30 Days</option>
                            <option value="one_year">1 Year</option>
                            <option value="custom">Custom</option>
                        </select>
                    </div>
                    <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
                        <label for="inputBillTo" class="form-label">Start Date :</label>
                    </div>
                    <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
                        <input type="date" class="form-control" #start_date formControlName="start_date"
                            id="inputClientName">
                    </div>
                    <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
                        <label for="inputBillTo" class="form-label">End Date :</label>
                    </div>
                    <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
                        <input type="date" class="form-control" #end_date formControlName="end_date"
                            id="inputClientName">
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="status_value">
                            <option [ngValue]="null" [disabled]="true">Order Status</option>
                            <option value="R">Registered</option>
                            <option value="I">In-Process</option>
                            <option value="S">Success</option>
                            <option value="E">Error</option>
                            <option value="F">Failed</option>
                            <option value="K">Kill</option>
                            <option value="C">Cancelled</option>
                            <option value="W">Wrong Parameter</option>
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <button mat-raised-button class="addButton" (click)="orderRefreshData();">Filter </button>
                    </div>
                </div>
            </form>
            <table class="table table-striped mt-25">
                <thead>
                    <tr>
                        <th scope="col">Sl No</th>
                        <th scope="col">Order Id</th>
                        <th scope="col">Order Date</th>
                        <th scope="col">Client Name</th>
                        <th scope="col">Partner Name</th>
                        <th scope="col">Apps Name</th>
                        <th scope="col">Status</th>
                        <th scope="col">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngIf="errorfilterappshow !== 0">
                        <td colspan="8" style="text-align: center;">
                            <p class="nodatafound"> No Data Found... </p>
                        </td>
                    </tr>
                    <tr *ngFor="let orderdata of orderviewdata; let i=index;" >
                        <td> {{ i+pageInfo.pageSize*pageInfo.pageIndex+1 }} </td>
                        <td> {{ orderdata.order_id }} </td>
                        <td> {{ orderdata.order_date }} </td>
                        <td> {{ orderdata.client_name }} </td>
                        <td> {{ orderdata.partner_name }} </td>
                        <td> {{ orderdata.app_name }} </td>
                        <td>
                            <div *ngIf="orderdata.order_status === 'I'">
                                Inprocess
                            </div>
                            <div *ngIf="orderdata.order_status === 'R'">
                                Register
                            </div>                            
                            <div *ngIf="orderdata.order_status === 'S'">
                                Sucess
                            </div>
                            <div *ngIf="orderdata.order_status === 'E'">
                                Error
                            </div>
                            <div *ngIf="orderdata.order_status === 'F'">
                                Fail
                            </div>
                            <div *ngIf="orderdata.order_status === 'C'">
                                Cancel
                            </div>
                            <div *ngIf="orderdata.order_status === 'K'">
                                Kill
                            </div>
                            <div *ngIf="orderdata.order_status === 'W'">
                                Wrong
                            </div>
                        </td>
                        <td>
                            <button class="addButton " mat-raised-button (click)="viewOrder(orderdata.order_id)">
                                View
                            </button>

                        </td>
                    </tr>
                </tbody>
            </table>
            <div *ngIf="errorfilterappshow ===0">
                <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                    [length]="pageInfo.totalElements" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 25,100]"
                    [pageIndex]="pageInfo" aria-label="Select page">
                </mat-paginator>
            </div>
        </div>


    </mat-card-content>


</mat-card>