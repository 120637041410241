<mat-card class="example-card">
    <mat-card-header>
        <span class="label">Invoice</span>
        <span class="example-spacer"></span>
    </mat-card-header>
    <div class="breakline"></div>
    <mat-card-content>
        <div class="example-container mt-10">
            <form id="invoice-filter" name="filterInvoiceForm" [formGroup]="filterInvoiceForm" novalidate>
                <!-- <div class="row g-3">
                    <div class="col-md-3 text-left"></div>
                    <div class="col-md-6" *ngIf="errorfilterinvoiceshow==true">
                        <p class="nodatafound"> No Data Found... </p>
                    </div>
                    <div class="col-md-6" *ngIf="errorfilterinvoiceshow==false">
                        <canvas id="canvas">{{chart}}</canvas>
                    </div>
                </div> -->

                <div class="row g-3 mt-15">                  
                    <div class="col-md-2 ">
                        <select class="form-control" formControlName="client_id"
                            (change)="partnerWithClient(); fetchAppNameForClient();">
                            <option Value="" [disabled]="true">Select Client</option>
                            <option *ngFor="let client of clientdata" [ngValue]="client">{{client.client_name}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="partner_id" (change)="fetchAppName();">
                            <option Value="" [disabled]="true">Select Partner</option>
                            <option *ngFor="let partner of partnerdata" [ngValue]="partner">{{partner.partner_name}}
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" formControlName="app_id">
                            <option Value="" [disabled]="true">Select App</option>
                            <option *ngFor="let app of appdata" [ngValue]="app">{{app.app_name}}</option>
                        </select>
                    </div>
                    <div class="col-md-2 text-left">
                        <select class="form-control" (change)="customDate(duration.value)" formControlName="duration"
                            #duration selected>                          
                            <!-- <option Value="" [disabled]="true">Select Duration</option>         -->
                            <option value="this_month" >This Month</option>                          
                            <option value="custom">Custom</option>
                        </select>
                    </div>                  
                    <div class="col-md-2 text-left" *ngIf="customdateFlag == false">
                        <button mat-raised-button class="addButton" (click)="invoiceRefreshData();">Filter </button>
                    </div>
                </div>
                <div class="row g-3  mt-15">
                    <!-- <div class="col-md-2 text-left"></div> -->
                    <!-- <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
                        <label for="inputBillTo" class="form-label">Month :</label>
                    </div> -->
                    <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
                        <select class="form-control" formControlName="month"  (change)="checkvaluemonth($event);"  #month> 
                        <option Value="" [disabled]="true">Select Month</option>
                        <option value="1">January</option>
                        <option value="2">February</option>
                        <option value="3">March</option>
                        <option value="4">April</option>
                        <option value="5">May</option>
                        <option value="6">June</option>
                        <option value="7">July</option>
                        <option value="8">August</option>
                        <option value="9">September</option>
                        <option value="10">October</option>
                        <option value="11">November </option>
                        <option value="12">December</option>
                        </select>
                    </div>
                    <!-- <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
                        <label for="inputBillTo" class="form-label">Year :</label>
                    </div> -->
                    <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
                        <input type="number" class="form-control"  formControlName="year"
                            id="inputClientName" placeholder="YYYY" (keyup)="checkvalueyear(year.value);"  #year  ><!--(keyup)="yearCheck($event)"--> 
                            <div *ngIf="filterInvoiceForm.get('year').invalid && (filterInvoiceForm.get('year').dirty || filterInvoiceForm.get('year').touched)">
                                <small *ngIf="filterInvoiceForm.get('year').errors.pattern " style="color: red;">
                                    Please enter valid year.
                                </small>
                            </div>
                            <!-- <div *ngIf="yearerrorstatus==true"> -->
                                <!-- <small *ngIf="yearerrorstatus==true" style="color: red;">
                                    Please enter valid yearhhh.
                                </small> -->
                            <!-- </div> -->
                        <!-- </div> -->
                    </div>
                    <!-- <div class="col-md-11 text-left" *ngIf="customdateFlag == true"></div> -->
                    <div class="col-md-2 text-left" *ngIf="customdateFlag === true">
                        <div *ngIf="valuemonth === '' || valueyear === ''">
                            <button mat-raised-button class="addButton" disabled style="opacity: 0.5;">Filter </button>
                        </div>
                        <div *ngIf="valuemonth !='' && valueyear !=''">
                            <button mat-raised-button class="addButton" (click)="invoiceRefreshData();">Filter </button>
                        </div>
                        
                    </div>
                </div>

                <table class="table table-striped mt-25">
                    <thead>
                        <tr>
                            <th scope="col">Sl No</th>
                            <th scope="col">Client Name</th>
                            <th scope="col">Partner Name</th>
                            <th scope="col">Apps</th>
                            <th scope="col">Quantity</th>                           
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngIf="errorfilterappshow !== 0">
                            <td colspan="8" style="text-align: center;">
                                <p class="nodatafound"> No Data Found... </p>
                            </td>
                        </tr>
                        <tr *ngFor="let order of orderdata; let i=index;">                     
                            <td> {{ i+pageInfo.pageSize*pageInfo.pageIndex+1 }} </td>
                            <td>{{ order.client_name }}</td>
                            <td>{{ order.partner_name }}</td>
                            <td>
                                <div *ngFor="let orderapp_id of order.appSet"> {{orderapp_id.app_name}} <hr style="opacity: 0.1;"></div>     
                                 
                            </td>
                            <td>
                                <div *ngFor="let orderapp_id of order.appSet"> {{orderapp_id.quantity}} <hr style="opacity: 0.1;" ></div>
                            </td>                        
                            <td *ngIf="order.appSet.length ==1">                            
                                <button *ngFor="let orderapp_id of order.appSet" class="addButton " mat-raised-button (click)="viewStatics(order.client_id,order.partner_id,orderapp_id.app_id)">
                                    View
                                </button>
                            </td>
                            <td *ngIf="order.appSet.length !==1">
                                <button class="addButton " mat-raised-button (click)="viewStatics(order.client_id,order.partner_id,null)">
                                    View
                                </button>
                            </td>
                        </tr>                       
                    </tbody>
                </table>
                <div *ngIf="errorfilterappshow ===0">
                    <mat-paginator #paginator class="demo-paginator" (page)="handlePageEvent($event)"
                        [length]="pageInfo.totalElement" [pageSize]="pageInfo.pageSize" [pageSizeOptions]="[5, 10, 25,100]"
                        [pageIndex]="pageInfo" aria-label="Select page">
                    </mat-paginator>
                </div>
            </form>
        </div>


    </mat-card-content>




</mat-card>