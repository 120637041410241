import { formatDate } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';
@Component({
  selector: 'app-view-api-logs',
  templateUrl: './view-api-logs.component.html',
  styleUrls: ['../../../styles.css','./view-api-logs.component.css']
})
export class ViewApiLogsComponent {
  call_date: any;
  constructor(private apicall: ApiservicesService, private router:ActivatedRoute) { }
  
  id: any;

  api_logs_details: any;
  api_response: any;
  api_status: any;
  client_name: any;
  api_name : any;
  input_parameters: any;
  ip_address: any;
  ngOnInit(): void {
    this.router.queryParams
      .subscribe(params => {
        this.id = params["id"];
       
        
        this.apicall.api_call_log_with_id(this.id).subscribe(params => {
          this.api_logs_details = params.body;
          this.api_logs_details = JSON.parse(JSON.stringify(this.api_logs_details));
          this.api_logs_details = this.api_logs_details["result"];
          console.log("55",this.api_logs_details.api_name);
          this.api_name =this.api_logs_details.api_name
          this.api_response =  this.api_logs_details.api_response
          this.api_status =this.api_logs_details.api_status
          this.client_name =this.api_logs_details.client_name
          this.input_parameters =this.api_logs_details.input_parameters
          this.ip_address =this.api_logs_details.ip_address
          this.call_date=formatDate(this.api_logs_details.api_call_date, 'dd-MMM-yyyy hh:mm:ss', 'en_US')       
        })
      }
      );
  }
}
