import { Injectable } from '@angular/core';
import { ApiservicesService } from './apiservices.service';

@Injectable({
  providedIn: 'root'
})
export class AuthserviceService{

  result:any;
  constructor(private login:ApiservicesService) { }

  IsLoggedIn(){
    if(localStorage.getItem('user_token') && localStorage.getItem('user_token') !=""){
      return true
    }else{
      return false;
    }
  }
}
