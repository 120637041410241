import { Component, OnInit } from '@angular/core';
import { AuthserviceService } from '../services/authservice.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent {

  constructor(private auth: AuthserviceService,private router: Router,private toastr: ToastrService){

  }

  ngOnInit(): void {
  
    this.toastr.success("Logged out successfully");
    setTimeout(function () {    window.location.href="#/login";   localStorage.setItem('user_token','');}, 100);

  }
}
