import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, RouterStateSnapshot} from '@angular/router';
import { Router } from '@angular/router';
import { AuthserviceService } from '../services/authservice.service';

export const authGuard: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  
  let _router=inject(Router);
  let auth_servce = inject(AuthserviceService);

  if(auth_servce.IsLoggedIn()){
    return true;
  }else{
    _router.navigate(['/']);
    return false;
  } 
};
