import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MyInfoService } from '../services/my-info.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-my-info',
  templateUrl: './my-info.component.html',
  styleUrls: ['./my-info.component.css']
})
export class MyInfoComponent {
  setflag: number | undefined;
  password: any;
  password2: any;
  password3: any;
  show = false;
  showPassword: boolean = false;
  showPasswordOnPress: boolean = false;
  showPasswordOnReEnter: boolean = false;
  showCurrentPasswordOnPress: boolean = false;
  password_error: boolean = false;
  admin_id: any;
  myinfodata: any;
  admin_company_name: any;
  admin_project_name: any;
  admin_name: any;
  admin_email_id: any;
  current_password: any;
  id: any;
  updatedData:any;
  constructor(
    private router: Router,
    private _formBuilder: FormBuilder,
    private users: HttpClient,
    private my_info: MyInfoService,
    private _snackBar: MatSnackBar, 
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {

    let userdataa: any = localStorage.getItem('id');
    this.id = atob(userdataa);
    const datata = { id: this.id };
    this.my_info.fetch_my_info(datata).subscribe(params => {
      this.myinfodata = params.body;
      this.admin_company_name = this.myinfodata.result.admin_company_name;
      this.admin_project_name = this.myinfodata.result.admin_project_name;
      this.admin_name = this.myinfodata.result.admin_name;
      this.admin_id = this.myinfodata.result.admin_id;
      this.admin_email_id = this.myinfodata.result.admin_email_id;
    });
  }
  openSetPassword(flag: number) {
    this.setflag = flag;

  }

  getFormdata(data: any) {
   let response_msg = 'Feedback saved successfully'
    const dataa = { new_password: data.new_password, re_new_password: data.re_new_password, id: this.id, current_password: data.current_password }
    this.my_info.update_password(dataa).subscribe(params => { 
      this.updatedData = params.body;
      this.updatedData = this.updatedData["status"];
      if (this.updatedData == true) {
        this.toastr.success('Password has been successfully updated!.');
        this.router.navigate(['/MyInformation']);
        setTimeout(function(){window.location.reload();}, 2000); 
      }
      else {
        this.toastr.error('Password has been unavailable to updated!.');
      }
    });

  }
}
