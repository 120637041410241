import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiservicesService {

  dev_api_base_url =environment.apiUrl;

  constructor(private users:HttpClient) { }

  headers = new HttpHeaders({
    'Content-Type': 'application/json',
    'Authorization': '' + localStorage.getItem('user_token'),
  });
  headers1=new HttpHeaders({
    //'Content-Type': 'multipart/form-data',
    'Authorization': '' + localStorage.getItem('user_token'),
    'Host': 'dev.java.reportlab.mygenomebox.in',
    'Accept': 'application/json',
  });


  checklogin(data:any){
    return this.users.post(this.dev_api_base_url+'v1/auth/authenticate',data,{headers: this.headers, observe: "response"});
  }

  getClientPartnerDetails(data:any,headr=this.headers){
     return this.users.post(this.dev_api_base_url+'v1/admin/fetch_client_partner_list',data,{headers: headr, observe: "response"});
  }

  getAllApp(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/fetch_app_list',data,{headers: this.headers, observe: "response"});
  }
  addClientPartner(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/add_client_or_partner',data,{headers: this.headers, observe: "response"});
  }

  getNotAssignedApp(data:any){
    return this.users.get(this.dev_api_base_url+'v1/admin/not_assigned_Apps',{params:data,headers: this.headers, observe: "response"});
  }

  addApps(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/add_new_app',data,{headers: this.headers1, observe: "response"});
  }

  deleteApp(data:any){
    return this.users.delete(this.dev_api_base_url+'v1/admin/remove_app',{params:data,headers: this.headers, observe: "response"});
  }

  assignApps(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/assign_app',data,{headers: this.headers, observe: "response"});
  }

  testImage(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/imageTest',data,{headers: this.headers1, observe: "response"});
  }

  removeClientorpartner(data:any){
    return this.users.delete(this.dev_api_base_url+'v1/admin/remove_client_or_partner',{params:data,headers: this.headers, observe: "response"});
  }

  unAssignApp(data:any){
    return this.users.delete(this.dev_api_base_url+'v1/admin/unassign_app',{params:data,headers: this.headers, observe: "response"});
  }

  singleOrderDetails(data:any){
    return this.users.get(this.dev_api_base_url+'v1/admin/specific_order_id/'+data+'',{headers: this.headers, observe: "response"});
  }

  api_call_log(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/api_call_log',data,{headers: this.headers, observe: "response"});
  }
  api_call_log_with_id(data:any){
    return this.users.get(this.dev_api_base_url+'v1/admin/api_call_log_with_id/'+data+'',{headers: this.headers, observe: "response"});
  }
  clientWithName(){
    return this.users.get(this.dev_api_base_url+'v1/admin/client_with_name',{headers: this.headers, observe: "response"});
  }
  partnerWithClient(data:any){
    return this.users.get(this.dev_api_base_url+'v1/admin/partner_with_client',{params:data,headers: this.headers, observe: "response"});
  }
  fetchAppName(data:any){
    return this.users.get(this.dev_api_base_url+'v1/admin/fetch_app_name',{params:data,headers: this.headers, observe: "response"});
  }
  order_list_fliter(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/order_list',data,{headers: this.headers, observe: "response"});
  }
  ord_count(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/ord_count',data,{headers: this.headers, observe: "response"});
  }
  image_upload_test(data:any){
    return this.users.post('http://localhost/uploadimagetesting/index.php/upload/upload',data,{observe: "response"});
  }
  specific_app_id(data:any){    
    return this.users.get(this.dev_api_base_url+'v1/admin/specific_app_id',{params:data,headers: this.headers, observe: "response"});
  }
  update_app(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/update_app',data,{headers: this.headers1, observe: "response"});
  }

  testImageUpload(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/imageTest',data,{headers: this.headers1, observe: "response"});
  }
  update_assigned_app(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/update_assigned_app',data,{headers: this.headers, observe: "response"});
  }
  get_ord_quantity(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/get_ord_quantity',data,{headers: this.headers, observe: "response"});
  }
  fetch_report_language(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/fetch_report_laguage',data,{headers: this.headers, observe: "response"});
  }
  fetch_client_name(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/fetch_client_name',data,{headers: this.headers, observe: "response"});
  }
  invoice_detail(data:any){
    return this.users.post(this.dev_api_base_url+'v1/admin/invoice_detail',data,{headers: this.headers, observe: "response"});
  }
  generate_invoice(data:any){    
    return this.users.get(this.dev_api_base_url+'v1/admin/generate_invoice',{params:data,headers: this.headers, observe: "response"});
  }
}
