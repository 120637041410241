<mat-card class="example-card">
    <mat-card-header>
        <span class="label">Clients</span>
        <span class="example-spacer"></span>
        <mat-card-actions>
            <button mat-raised-button class="addButton"  (click)="addClient()">Add New
                <mat-icon>add</mat-icon>
            </button>
           
        </mat-card-actions>
   
    </mat-card-header>
    <div class="breakline"></div>
    <mat-card-content>
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">Sl No</th>
            <th scope="col">Client Id</th>
            <th scope="col">Client Name</th>
            <th scope="col">Partner ID</th>
            <th scope="col">Partner Name</th>
            <th scope="col">Apps Subscribed</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="datalength === 0">
            <td colspan="7" style="text-align: center;">
              <p class="nodatafound"> No Data Found... </p>
            </td>
          </tr>
          <tr *ngFor="let clientdata of clientdata; let i=index;">
            <td> {{ i+pageInfo.pageSize*pageInfo.pageIndex+1}} </td>
            <td> {{ clientdata.client_id }} </td>
            <td> {{ clientdata.client_name }} </td>
            <td> {{ clientdata.partner_id }} </td>
            <td> {{ clientdata.partner_name }} </td>
            <td style="width: 30%;">
              <span class="comma" *ngFor="let applist of clientdata.appList">
                {{ applist.app_id }}
              </span>
            </td>
            <td>
              <button class="btn btn-sm button-success" (click)="goClient(clientdata.client_id,clientdata.partner_id)">
                Edit
              </button>
              <button class="btn btn-sm button-danger" (click)="openConfirmationDialog(clientdata.client_id,clientdata.partner_id)">
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>     
      <div  *ngIf="datalength !== 0">
        <mat-paginator 
        #paginator class="demo-paginator"
         (page)="handlePageEvent($event)"
          [length]="pageInfo.totalElements"
           [pageSize]="pageInfo.pageSize"   
           [pageSizeOptions]="[5, 10, 25,100]"       
           [pageIndex]="pageInfo"
           aria-label="Select page">
        </mat-paginator>
    </div>
    </mat-card-content>

  </mat-card>
