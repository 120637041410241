<mat-card class="example-card">
  <mat-card-header>
    <span class="label"> Edit Clients</span>
    <span class="example-spacer"></span>
    <mat-card-actions>
      <button mat-raised-button (click)="goto(previousurl)">Cancel</button>
    </mat-card-actions>

  </mat-card-header>
  <div class="breakline"></div>
  <mat-card-content *ngFor="let clientdata of clientdata">
    <div class="example-container" *ngIf="clientdata.client_id === clientid && clientdata.partner_id == partnerid">
      <div class="row g-12">
        <table class="table table-borderless">
          <tbody>
            <tr>
              <td>
                <tr>
                  <td width="150" class="form-label">Client Name  :</td>
                  <td class="font: normal normal 403 15px/20px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;">{{
                    clientdata.client_name }}</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td width="150" class="form-label">Client Id  :</td>
                  <td>{{ clientdata.client_id }}</td>
                </tr>
              </td>
            </tr>
            <tr>
              <td>
                <tr>
                  <td width="150" class="form-label">Partner Name  :</td>
                  <td *ngIf="clientdata.partner_name !==null">{{ clientdata.partner_name }}</td>
                  <td *ngIf="clientdata.partner_name ==null">-</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td width="150" class="form-label">Partner Id  :</td>
                  <td *ngIf="clientdata.partner_id !==null">{{ clientdata.partner_id }}</td>
                  <td *ngIf="clientdata.partner_id ==null">-</td>
                </tr>
              </td>
            </tr>
            <tr>
              <td>
                <tr>
                  <td width="150" class="form-label">Bill To  :</td>
                  <td>{{ clientdata.bill_to }}</td>
                </tr>
              </td>
              <td>
                <tr>
                  <td width="150" class="form-label">Currency  :</td>
                  <td>{{ clientdata.currency }}</td>
                </tr>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <h2 class="hr-lines g-12" style="margin-top:20px ;">Assign Apps</h2>
      <div class="row">
        <div class="col-md-10"></div>
        <div class="col-md-2">
          <a (click)="newAppOpen();" style="color: #0d6efd;text-decoration: underline;cursor: pointer;">Assign New App +
          </a>
        </div>
        <div class="example-container" style="background-color: #F8FCFF ; margin: 10px;" *ngIf="appflag == true">
          <form #assignapp="ngForm" (submit)="assignApp(assignapp.value)">
            <div class="row g-4" style="margin-top: 20px;">
              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label">App :</label>
              </div>
              <!-- <div class="col-md-2">

              </div> -->
              <div class="col-md-4 text-left" *ngIf="unassignedAppdata">
                <select class="form-control" #selectedapp="ngModel" ngModel name="selectedapp" required
                  [class.is-invalid]="selectedapp.invalid && selectedapp.touched" (change)="getcurrency($event)">
                  <option value=""> Select App </option>
                  <option *ngFor="let appdetails1 of appdetails" [value]="appdetails1.app_name">{{
                    appdetails1.app_name}}
                  </option>
                </select>
                <div *ngIf="selectedapp.errors &&(selectedapp.invalid && selectedapp.touched)">
                  <small class="text-danger" *ngIf="selectedapp.errors"> App Name Is Required </small>
                </div>
              </div>
              <div class="col-md-4 text-left" *ngIf="!unassignedAppdata">
                No Unassigned App
              </div>

            </div>
            <div class="row g-4" style="margin-top: 20px;">
              <!-- <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label">Type of report :</label>
              </div>
              <div class="col-md-8 text-left"> -->
                <!-- <mat-radio-group aria-label="Select an option" #report_type="ngModel" ngModel name="report_type"
                  ngDefaultControl required [class.is-invalid]="report_type.invalid && report_type.touched">
                  <mat-radio-button value="original">Original</mat-radio-button>
                  <mat-radio-button value="whitelabel">White Lable</mat-radio-button>
                  <mat-radio-button value="cobranded">Co-branded</mat-radio-button>
                  <mat-radio-button value="text">Text</mat-radio-button>
                  <!- <mat-radio-button value="custom">Custom</mat-radio-button> ->
                </mat-radio-group>
                <div *ngIf="report_type.errors &&(report_type.invalid && report_type.touched)">
                  <small class="text-danger" *ngIf="report_type.errors"> Report Type Is Required </small>
                </div>-->
              <!-- </div> -->
            </div>
            <div class="row g-4" style="margin-top: 20px;">
              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label">Price :</label>
              </div>
              <div class="col-md-3 text-left">
                <mat-radio-group aria-label="Select an option" #price="ngModel" ngModel name="price" ngDefaultControl
                  required [class.is-invalid]="price.invalid && price.touched">
                  <mat-radio-button value="Fixed" (click)="openValue(1,'','','','','','','')">Fixed</mat-radio-button>
                  <mat-radio-button value="Volumetric"
                    (click)="openValue(2,'','','','','','','')">Volumetric</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="price.errors &&(price.invalid && price.touched)">
                  <small class="text-danger" *ngIf="price.errors"> Price Is Required </small>
                </div>
              </div>
            </div>
            <div class="row g-4" style="margin-top: 20px;" *ngIf="fixedflag == 1">
              <div class="col-md-2 text-left"></div>

              <div class="col-md-2 text-left">
                <input type="text" class="form-control" id="fixed_value" #fixed_price="ngModel" ngModel
                  name="fixed_price" list="fixed_value" placeholder="2000">
              </div>
            </div>

            <div class="row g-4" style="margin-top: 20px;" *ngIf="fixedflag == 2">
              <div class="col-md-2 text-left"></div>
              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label volumetric-label required">1.Price for 0-100</label>
              </div>

              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label volumetric-label required">2.Price for 101-500</label>
              </div>

              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label volumetric-label required">3.Price for 500+</label>
              </div>
            </div>

            <div class="row g-4" *ngIf="fixedflag == 2">
              <div class="col-md-2 text-left"></div>
              <div class="col-md-2 text-left">
                <input type="text" class="form-control" id="volumetric1_value" #volumetric1_value="ngModel" ngModel
                  name="volumetric1_value" list="fixed_value" placeholder="Enter Price">
              </div>

              <div class="col-md-2 text-left">
                <input type="text" class="form-control" id="volumetric2_value" #volumetric2_value="ngModel" ngModel
                  name="volumetric2_value" list="fixed_value" placeholder="Enter Price">
              </div>

              <div class="col-md-2 text-left">
                <input type="text" class="form-control" id="volumetric3_value" #volumetric3_value="ngModel" ngModel
                  name="volumetric3_value" list="fixed_value" placeholder="Enter Price">
              </div>
            </div>
            <div class="row g-4" style="margin-top: 20px;">
              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label">Report Language :</label>
              </div>
              <div class="col-md-3 text-left">
                <mat-checkbox *ngFor="let languages of languages" (change)="getLanguages(languages.language,$event)">
                  {{ languages.language | uppercase }}
                </mat-checkbox>
              </div>
            </div>
            <div class="row g-4" style="margin-top: 20px;">
              <div class="col-md-2 text-left">
                <label for="inputBillTo" class="form-label">Type of report :</label>
              </div>
              <div class="col-md-6 text-left">
                <mat-checkbox *ngFor="let reportess1 of reportess1" (change)="getReportType(reportess1.report_type,$event)">
                  {{ reportess1.report_type | uppercase }}
                </mat-checkbox>
              </div>
            </div>
            <div class="row" style="margin-top:20px; margin-bottom:20px ;">
              <mat-card-actions>
                <div class="col-md-10">
                </div>
                <div class="col-md-2">
                  <button mat-raised-button class="addButton" style="margin-right: 10px;">Save </button>
                </div>
              </mat-card-actions>
            </div>
          </form>
        </div>
        <div class="col-md-12">

          <table class="table appviewtable">
            <thead>
              <tr>
                <td class="head" scope="col" style="width: 14%;">App ID</td>
                <td class="head" scope="col"  style="width: 13%;">App Name</td>
                <td class="head" scope="col"  style="width: 14%;">Report Type</td>
                <td class="head" scope="col"  style="width: 16%;">Price</td>

              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="4" style="padding:0; border:none">
                  <div ngbAccordion [closeOthers]="true">
                    <div *ngFor="let applist of clientdata.appList; let i=index;" ngbAccordionItem [collapsed]="true">
                      <h2 ngbAccordionHeader>
                        <button ngbAccordionButton
                          (click)="openValue(applist.price_type,applist.fixed_price,applist.vol_price1,applist.vol_price2,applist.vol_price3,applist.app_id,languages1[applist.ass_app_id],reportess2[applist.ass_app_id])">
                          <table table>
                            <tr
                              (click)="openValue(applist.price_type,applist.fixed_price,applist.vol_price1,applist.vol_price2,applist.vol_price3,applist.app_id,languages1[applist.ass_app_id],reportess2[applist.ass_app_id])">
                              <td  style="width: 15%;"> {{ applist.app_id }} </td>
                              <td  style="width: 15%;"> {{ applist.app_name }} </td>
                              <td  style="width: 15%;"> {{ applist.report_type }} </td>
                              <td  style="width: 15%;">
                                <span *ngIf="applist.price_type === 'fixed' || applist.price_type === 'Fixed'">
                                  <p> {{ applist.fixed_price }} </p>
                                </span>
                                <span
                                  *ngIf="applist.price_type === 'volumetric' || applist.price_type === 'Volumetric'">
                                  <p> {{ applist.vol_price1 }} (0-100) </p>
                                  <p> {{ applist.vol_price2 }} (101-500) </p>
                                  <p> {{ applist.vol_price3 }} (500+) </p>
                                </span>
                              </td>
                            </tr>
                          </table>
                        </button>
                        <mat-icon class="close-icon" color="warn" style="margin-top: 8px;cursor: pointer;"
                          title="Unassign App"
                          (click)="openConfirmationDialog(clientdata.client_id,clientdata.partner_id,applist.app_name)">close</mat-icon>
                      </h2>
                      <div ngbAccordionCollapse>
                        <div ngbAccordionBody>
                          <ng-template>
                            <form #updateassignapp="ngForm" (submit)="update_assign_app(updateassignapp.value)">
                              <div class="row g-4" style="margin-top: 20px;">
                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label">App :</label>
                                </div>
                                <div class="col-md-4 text-left">
                                  <select class="form-control" #selectedapp="ngModel" [ngModel]="applist.app_name"
                                    name="selectedapp">
                                    <option [value]="applist.app_name"> {{ applist.app_name }} </option>
                                    <!-- <option *ngFor="let appdetails2 of appdetails" [value]="appdetails2.app_name">{{ appdetails2.app_name}}
                                    </option> -->
                                  </select>
                                  <div *ngIf="selectedapp.errors &&(selectedapp.invalid && selectedapp.touched)">
                                    <small class="text-danger" *ngIf="selectedapp.errors"> App Name Is Required </small>
                                  </div>
                                </div>

                              </div>
                              <div class="row g-4" style="margin-top: 20px;">
                                <!-- <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label">Type of report :</label>
                                </div> -->
                                <!-- <div class="col-md-8 text-left">
                                  <mat-radio-group>
                                    <mat-radio-button *ngFor="let report_typee of report_typee" #report_type="ngModel"
                                      name="report_type" ngDefaultControl required
                                      [class.is-invalid]="report_type.invalid && report_type.touched"
                                      [value]="report_typee.value"
                                      [checked]="report_typee.value === applist.report_type"
                                      [ngModel]="applist.report_type" (click)="updatereport_type($event);">
                                      {{ report_typee.name }}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </div> -->
                              </div>
                              <div class="row g-4" style="margin-top: 20px;">
                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label">Price :</label>
                                </div>
                                <div class="col-md-3 text-left">
                                  <mat-radio-group aria-label="Select an option">
                                    <mat-radio-button *ngFor="let price_type of price_type" #price="ngModel" ngModel
                                      name="price" ngDefaultControl required
                                      [class.is-invalid]="price.invalid && price.touched"
                                      [checked]="applist.price_type === price_type.value" [value]="price_type.value"
                                      (click)="openValue(price_type.value,applist.fixed_price,applist.vol_price1,applist.vol_price2,applist.vol_price3,applist.app_id,languages1[applist.ass_app_id],reportess2[applist.ass_app_id])">
                                      {{ price_type.name }}
                                    </mat-radio-button>
                                  </mat-radio-group>
                                </div>
                              </div>
                              <div class="row g-4" style="margin-top: 20px;" *ngIf="fixedflag == applist.app_id+1">
                                <div class="col-md-2 text-left"></div>

                                <div class="col-md-2 text-left">
                                  <input type="text" class="form-control" #fixedprice="ngModel" [ngModel]="fixed_price"
                                    name="fixedprice" value="{{fixed_price}}" id="fixed_value" list="fixed_value"
                                    placeholder="2000">
                                </div>
                              </div>

                              <div class="row g-4" style="margin-top: 20px;" *ngIf="fixedflag == applist.app_id+2">
                                <div class="col-md-2 text-left"></div>
                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label volumetric-label required">1.Price for
                                    0-100</label>
                                </div>

                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label volumetric-label required">2.Price for
                                    101-500</label>
                                </div>

                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label volumetric-label required">3.Price for
                                    500+</label>
                                </div>
                              </div>

                              <div class="row g-4" *ngIf="fixedflag == applist.app_id+2">
                                <div class="col-md-2 text-left"></div>
                                <div class="col-md-2 text-left">
                                  <input type="text" class="form-control" #volprice1="ngModel" [ngModel]="vol_price1"
                                    name="volprice1" value="{{vol_price1}}" id="volumetric1_value" list="fixed_value"
                                    placeholder="Enter Price">
                                </div>

                                <div class="col-md-2 text-left">
                                  <input type="text" class="form-control" #volprice2="ngModel" [ngModel]="vol_price2"
                                    name="volprice2" value="{{vol_price2}}" id="volumetric2_value" list="fixed_value"
                                    placeholder="Enter Price">
                                </div>

                                <div class="col-md-2 text-left">
                                  <input type="text" class="form-control" #volprice3="ngModel" [ngModel]="vol_price3"
                                    name="volprice3" value="{{vol_price3}}" id="volumetric3_value" list="fixed_value"
                                    placeholder="Enter Price">
                                </div>
                              </div>
                              <div class="row g-4" style="margin-top: 20px;">
                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label">Report Language :</label>
                                </div>
                                <div class="col-md-5 text-left">
                                  <mat-checkbox *ngFor="let languages of languages1[applist.ass_app_id]; let j=index"
                                    (change)="getLanguages(languages.language,$event)"
                                    [checked]="languagess[applist.ass_app_id][j].isSelected">
                                    {{ languages.language | uppercase }}
                                  </mat-checkbox>
                                </div>
                              </div>
                              <div class="row g-4" style="margin-top: 20px;">
                                <div class="col-md-2 text-left">
                                  <label for="inputBillTo" class="form-label">Type of report  :</label>
                                </div>
                                <div class="col-md-5 text-left">
                                  <mat-checkbox *ngFor="let report of reportess2[applist.ass_app_id]; let j=index"
                                    (change)="getReportType(report.report_type,$event)"
                                    [checked]="reportetype[applist.ass_app_id][j].isSelected">
                                    {{ report.report_type | uppercase }}
                                  </mat-checkbox>
                                </div>
                              </div>
                              <div class="row" style="margin-top:20px; margin-bottom:20px ;">
                                <mat-card-actions>
                                  <div class="col-md-10">
                                  </div>
                                  <div class="col-md-2">
                                    <button mat-raised-button class="addButton" style="margin-right: 10px;"> Update
                                    </button>
                                  </div>
                                </mat-card-actions>
                              </div>
                            </form>
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <!-- <td>
                  <mat-icon class="close-icon" color="warn" style="margin-top: 8px;cursor: pointer;"
                    title="Unassign App"
                    (click)="openConfirmationDialog(clientdata.client_id,clientdata.partner_id,applist.app_name)">close</mat-icon>
                </td> -->
              </tr>
            </tbody>
          </table>
        </div>
      </div>



    </div>


  </mat-card-content>

</mat-card>