import { Component,Input  } from '@angular/core';
import {MatDialog, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['../../styles.css','./modal.component.css']
})
export class MODALComponent {
  constructor(public dialogRef: MatDialogRef<MODALComponent>) {}
  public confirmMessage:string | undefined;
  public delete:string | undefined;
  public button_label :string |undefined;
}
