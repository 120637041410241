<mat-card class="example-card">
    <mat-card-header>
        <span class="label">Apps</span>
        <span class="example-spacer"></span>
        <mat-card-actions>
            <button mat-raised-button class="addButton " (click)="addApps()">Add New
                <mat-icon>add</mat-icon>
            </button>

        </mat-card-actions> </mat-card-header>
    <div class="breakline"></div>
    <mat-card-content>
        <div class="row mb-20 ml-10 mr-10">
            <div class="example-second-container">
                <div class="row g-4" *ngIf="datalength === 0">
                    <div class="col-md-12" style="text-align: center;">
                        <p class="nodatafound"> No Data Found... </p>
                    </div>
                </div>
                <span *ngIf="datalength != 0">
                    <div class="row g-4" *ngFor="let appdetails of appdetails">                    
                        <div class="col-md-1 text-left" >                    
                            <img class="imageheight" [src]="appdetails.app_icon">
                        </div>
                        <div class="col-md-6 text-left">
                            <div>
                                <label for="inputBillTo" class="form-label">{{ appdetails.app_name }}</label>
                            </div>
                            <div>
                                <label for="inputBillTo" class="form-label text-left app-des"><p>{{ appdetails.app_description }}</p> </label>
                            </div>
                            <div>
                                <label for="inputBillTo" class="form-label text-left app-ID primarytheme" style="text-decoration: underline;">App
                                    ID - {{ appdetails.app_id }}</label>
                            </div>
                        </div>
                        <div class="col-md-3 text-left">
                            <div>
                                <label for="inputBillTo" class="form-label primarytheme app-ID">Co-developer</label>
                            </div>
                            <div>
                                <label for="inputBillTo" class="form-label text-left primarytheme app-ID" >{{ appdetails.codeveloper }}</label>
                            </div>
    
                            <div class="mt-15">
                                <label for="inputBillTo" class="form-label primarytheme app-ID">{{ appdetails.last_update }}</label>
                            </div>
    
                        </div>
                        <div class="col-md-2" style="text-align: center; padding: 30px;">
                            <button mat-raised-button class="button-success" (click)="goApps(appdetails.app_id)">Edit </button>
                            <button mat-raised-button  class="button-danger" (click)="openConfirmationDialog(appdetails.app_id)" >Delete </button>
                        </div>
                        <hr style="opacity: 0.1;">
                    </div> 
                </span>               
            </div> 
            <div *ngIf="datalength !== 0">
            <mat-paginator #paginator class="demo-paginator"
            (page)="handlePageEvent($event)"
             [length]="pageInfo.totalElements"
              [pageSize]="pageInfo.pageSize"   
              [pageSizeOptions]="[5, 10, 25,100]"       
              [pageIndex]="pageInfo"
              aria-label="Select page" >
           </mat-paginator>      
        </div>              
        </div>

    </mat-card-content>
</mat-card>