import { AppComponent } from '../app.component';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Chart from 'chart.js/auto';
import { ApiservicesService } from '../services/apiservices.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  chart: any = [];
  clientdata: any;
  appdata: any;
  orderdata: any;
  datalength: any;
  customdateFlag: boolean = false;
  partnerdata: any;
  end_date: any;
  start_date: any;
  filterInvoiceForm: FormGroup | any;
  app_names: any;
  num_of_ords: any;
  app_name: any;
  num_of_ord: any;
  countorder: any = [];
  sumNumber: any;

  errorfilterinvoiceshow: boolean = false;
  startdate: any = "";
  enddate: any = "";
  constructor(private router: Router,
    private apicall: ApiservicesService,
    private _formBuilder: FormBuilder,) { }
  ngOnInit() {
    this.filterInvoiceForm = this._formBuilder.group({
      client_id: ['', []],
      partner_id: ['', []],
      duration: ['this_month', []],
      app_id: ['', []],
      status_value: ['', []],
      start_date: [null, []],
      end_date: [null, []],
      order_id: ['', []]
    });
    this.invoiceListGaph();
    this.clientWithName();
    this.fetchApp();
  }

  invoiceListGaph() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var start_dates = formatDate(firstDay, 'dd-MMM-yyyy', 'en_US');
    var end_date = formatDate(date, 'dd-MMM-yyyy', 'en_US');
    let payload = {
      "client_id": null,
      "partner_id": null,
      "app_id": null,
      "start_date": start_dates,
      "end_date": end_date

    }
    this.apicall.ord_count(payload).subscribe(params => {
      this.orderdata = params.body;
      this.orderdata = JSON.parse(JSON.stringify(this.orderdata));
      this.orderdata = this.orderdata["result"];
      // let total=0;
      // for(let tvalue of  this.orderdata.num_of_ord){
      //   total +=tvalue
      // }
      this.app_name = this.orderdata.map((result: any) => result.app_name)
      this.num_of_ord = this.orderdata.map((result: any) => result.num_of_ord)
      this.countorder = this.orderdata.map((result: any) => result.num_of_ord)
      this.sumNumber = this.countorder.reduce((acc: number, num: number) => acc + num, 0);

      this.chart = new Chart('canvas', {
        type: 'bar',
        data: {
          labels: this.app_name,

          datasets: [
            {
              // label: 'All',
              data: this.num_of_ord,
              borderWidth: 1,
              backgroundColor: [
                'rgba(23, 74, 132, 1)',
              ],
              barPercentage: 0.5,
              barThickness: 40,
              maxBarThickness: 231,
              minBarLength: 1,

            }
          ],
        },
        options: {
          plugins: {
            legend: {
              display: false
            }
          }
        }
      });
      this.errorfilterinvoiceshow = false;
    },
      (err) => {
        if (err.status == 0) {
          this.errorfilterinvoiceshow = true;
          this.orderdata = [];

        }
      });





  }

  viewStatics(id: any) {
    this.router.navigate(['/view-statistics']);
  }

  clientWithName() {
    this.clientdata = null;
    this.apicall.clientWithName().subscribe(params => {
      this.clientdata = params.body;
      this.clientdata = JSON.parse(JSON.stringify(this.clientdata));
      this.clientdata = this.clientdata["result"];

    });
  }

  fetchApp() {
    this.appdata = null;
    const data = { clientId: '', partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  partnerWithClient() {
    this.partnerdata = null;
    const data = { client_id: this.filterInvoiceForm.value.client_id.client_id };
    this.apicall.partnerWithClient(data).subscribe(params => {
      this.partnerdata = params.body;
      this.partnerdata = JSON.parse(JSON.stringify(this.partnerdata));
      this.partnerdata = this.partnerdata["result"];
    });
  }

  fetchAppNameForClient() {
    this.appdata = null;
    const data = { clientId: this.filterInvoiceForm.value.client_id.client_id, partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  fetchAppName() {
    this.appdata = null;
    const data = { clientId: this.filterInvoiceForm.value.client_id.client_id, partnerId: this.filterInvoiceForm.value.partner_id.partner_id };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  customDate(value: any) {
    if (value == "custom") {
      this.customdateFlag = true;
    } else {
      this.customdateFlag = false;
    }
  }

  checkvaluestartdate(startdate:any){
   this.startdate=startdate.target.value;
  }

  checkvalueenddate(enddate:any){
    this.enddate=enddate.target.value;
  }

  invoiceFilter() {
    let today_date = new Date();
    let today_date1 = new Date();
    if (this.filterInvoiceForm.value.duration == "last_day") {//last 30days
      this.start_date = formatDate(today_date.setDate(today_date.getDate() - 30), 'dd-MMM-yyyy', 'en-US');
      this.end_date = formatDate(today_date1.setDate(today_date1.getDate()), 'dd-MMM-yyyy', 'en-US');
    } else if (this.filterInvoiceForm.value.duration == "one_year") {//one year
      this.start_date = formatDate(today_date.setDate(today_date.getDate() - 365), 'dd-MMM-yyyy', 'en-US');
      this.end_date = formatDate(today_date1.setDate(today_date1.getDate() + 1), 'dd-MMM-yyyy', 'en-US');
    } else if (this.filterInvoiceForm.value.duration == "this_month") { //this month    
      var firstDay = new Date(today_date.getFullYear(), today_date.getMonth(), 1);
      this.start_date = formatDate(firstDay, 'dd-MMM-yyyy', 'en_US');
      this.end_date = formatDate(today_date, 'dd-MMM-yyyy', 'en_US');
    } else if (this.filterInvoiceForm.value.duration == "custom") {
      this.start_date = formatDate(this.filterInvoiceForm.value.start_date, 'dd-MMM-yyyy', 'en_US');
      this.end_date = formatDate(this.filterInvoiceForm.value.end_date, 'dd-MMM-yyyy', 'en_US');
    } else {
      var firstDay = new Date(today_date.getFullYear(), today_date.getMonth(), 1);
      this.start_date = formatDate(firstDay, 'dd-MMM-yyyy', 'en_US');
      this.end_date = formatDate(today_date, 'dd-MMM-yyyy', 'en_US');
    }
    let payload = {
      "start_date": this.start_date,
      "end_date": this.end_date,
      "client_id": this.filterInvoiceForm.value.client_id.client_id,
      "partner_id": this.filterInvoiceForm.value.partner_id.partner_id,     
      "app_id": this.filterInvoiceForm.value.app_id.app_id,

    }
    this.apicall.ord_count(payload).subscribe(params => {
      this.orderdata = params.body;
      this.orderdata = JSON.parse(JSON.stringify(this.orderdata));
      // this.chart.destroy();
      if (this.orderdata["status"] != 202) {
        this.orderdata = this.orderdata["result"];

        this.countorder = this.orderdata.map((result: any) => result.num_of_ord)
        this.sumNumber = this.countorder.reduce((acc: number, num: number) => acc + num, 0);


        this.app_names = this.orderdata.map((result: any) => result.app_name)
        this.num_of_ords = this.orderdata.map((result: any) => result.num_of_ord);
        this.chart.destroy();
        this.chart = new Chart('canvas', {
          type: 'bar',
          data: {
            labels: this.app_names,

            datasets: [
              {
                // label: 'All',
                data: this.num_of_ords,
                borderWidth: 1,
                backgroundColor: [
                  'rgba(23, 74, 132, 1)',
                ],
                barPercentage: 0.5,
                barThickness: 40,
                maxBarThickness: 231,
                minBarLength: 1,

              }
            ],
          },
          options: {
            plugins: {
              legend: {
                display: false
              }
            }
          }
        });
        this.errorfilterinvoiceshow = false;
      }
    },
      (err) => {
        if (err.status == 0) {
          this.errorfilterinvoiceshow = true;
          this.orderdata = [];

        }

      });


  }
}

