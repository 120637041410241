import { Component } from '@angular/core';
import { Router } from '@angular/router';
import Chart from 'chart.js/auto';
import { ApiservicesService } from '../services/apiservices.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '@angular/common';
import { PageEvent } from '@angular/material/paginator';
@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.css']
})
export class StatisticsComponent {
  chart: any = [];
  clientdata: any;
  appdata: any;
  orderdata:any;
  datalength: any;
  customdateFlag: boolean = false;
  partnerdata:any;
  year: any;
  month: any;
  filterInvoiceForm: FormGroup | any;
  app_names: any;
  num_of_ords: any;
  app_name: any;
  num_of_ord: any;
  errorfilterinvoiceshow: boolean = false;
  errorshow: boolean = false;
  yearerrorstatus:boolean=false;
  errorfilterappshow:any
  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  pageEvent!: PageEvent;
  pageInfo: any;
  appSet: any;
  statusstatistics: any;
  yearerror:any =""
  customDateValidation: boolean=true;
  valueyear: any = "";
  valuemonth: any = "";
  constructor(private router: Router,
  private apicall: ApiservicesService,
  private _formBuilder: FormBuilder,) { }
  ngOnInit() {
    this.filterInvoiceForm = this._formBuilder.group({
      client_id: ['', []],
      partner_id: ['', []],
      duration: ['this_month', []],
      app_id: ['', []],
      status_value: ['', []],
      month: ['', []],
      year: ['', [Validators.pattern('[0-9]{4}')]],
      order_id: ['', []]
    });
    this.pageInfo = {
      pageSize: 10,
      totalElement: 0,
      totalPages: 0,
      pageIndex: 0

    }
    this.handlePageEvent(this.pageInfo)
    this.clientWithName();
    this.fetchApp();

  } 
  
  // yearCheck(data:any){
  //   let event=data.target.value;
  //   this.year = new Date().getFullYear();   
  //   this.month = new Date().getMonth() + 1;
  //     if(event > this.year){
  //       this.yearerrorstatus=true;
  //       console.log("hhfddodk");
        
  //       // this.yearerror="Enter Valid Year";
  //     }else{
  //       this.yearerrorstatus=false;
  //     }
    
    

  // }

  handlePageEvent(e: PageEvent) {   
    this.pageInfo.pageEvent = e;  
    this.pageInfo.totalElement = e.length;
    this.pageInfo.pageSize = e.pageSize;
    this.pageInfo.pageIndex = e.pageIndex;   
    this.invoiceFilter();
 
   
  }
 
  invoiceRefreshData(){
    this.pageInfo = {
      pageSize: 10,
      totalElement: 0,
      totalPages: 0,
      pageIndex: 0
    }
    this.handlePageEvent(this.pageInfo)
  }

  viewStatics(client_id: any,partner_id:any,app_id:any) {
    let payload = {month: this.month,
      year: this.year,
      client_id: client_id,
      partner_id: partner_id,
      app_id: app_id
      };    
      this.router.navigate(['/View-invoice'],    
      { state: payload });
  }

  clientWithName() {
    this.clientdata=null;
    this.apicall.clientWithName().subscribe(params => {
      this.clientdata = params.body;
      this.clientdata = JSON.parse(JSON.stringify(this.clientdata));
      this.clientdata = this.clientdata["result"];

    });
  }

  fetchApp() {
    this.appdata=null;
    const data = { clientId: '', partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  partnerWithClient() {
    this.partnerdata=null;
    const data = { client_id: this.filterInvoiceForm.value.client_id.client_id };
    // const data = {client_id:1};
    this.apicall.partnerWithClient(data).subscribe(params => {
      this.partnerdata = params.body;
      this.partnerdata = JSON.parse(JSON.stringify(this.partnerdata));
      this.partnerdata = this.partnerdata["result"];     
    });
  }

  fetchAppNameForClient() {
    this.appdata=null;
    const data = { clientId: this.filterInvoiceForm.value.client_id.client_id, partnerId: '' };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  fetchAppName() {
    this.appdata=null;
    const data = { clientId: this.filterInvoiceForm.value.client_id.client_id, partnerId: this.filterInvoiceForm.value.partner_id.partner_id };
    this.apicall.fetchAppName(data).subscribe(params => {
      this.appdata = params.body;
      this.appdata = JSON.parse(JSON.stringify(this.appdata));
      this.appdata = this.appdata["result"];
    });
  }

  customDate(value: any) {
    if (value == "custom") {
      this.customdateFlag = true;
    } else {
      this.customdateFlag = false;
    }
  }

  checkvaluemonth(month:any){
    this.valuemonth=month.target.value; 
  }
  checkvalueyear(year:any){
    this.valueyear=year;  
  }

  invoiceFilter() {  
    if (this.filterInvoiceForm.value.duration == "this_month") {//current month,year
      this.year = new Date().getFullYear();     
      this.month = new Date().getMonth() + 1;
    } else if (this.filterInvoiceForm.value.duration == "custom") {//custom month,year    
        this.month =this.filterInvoiceForm.value.month; 
       this.year = this.filterInvoiceForm.value.year;         
    } else {
      this.month = null;
      this.year = null;
    }
    let payload = {
      "month": this.month,
      "year": this.year,
      "client_id": this.filterInvoiceForm.value.client_id.client_id,
      "partner_id": this.filterInvoiceForm.value.partner_id.partner_id,
      "app_id": this.filterInvoiceForm.value.app_id.app_id,
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
    }    
    this.apicall.get_ord_quantity(payload).subscribe(params => {
      this.orderdata = params.body;
      this.statusstatistics = this.orderdata["status"];  
      this.orderdata = JSON.parse(JSON.stringify(this.orderdata));
      if(this.orderdata["result"]){
        this.pageInfo.totalElement = this.orderdata["result"].totalElement;    
        this.orderdata = this.orderdata["result"]['data'];  
      }    
      if(this.statusstatistics == true) {  
          this.errorfilterappshow = 0;        
      }  
      else{       
        this.errorfilterappshow = 1; 
        this.orderdata = [];
      }      
    },);

    


  }

}
