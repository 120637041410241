<mat-card class="example-card">
    <mat-card-header>
        <span class="label">My Information</span>
        <span class="example-spacer"></span>
    </mat-card-header>
    <div class="breakline"></div>

    <mat-card-content>
        <form #myInfo="ngForm" (submit)="getFormdata(myInfo.value)">

            <div class="example-container mt-10">
                <div class="row g-3">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Company Name :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" id="inputClientName" value="{{admin_company_name}}" disabled>
                    </div>
                </div>
                <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Project Name :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" id="inputClientName" value="{{admin_project_name}}"
                            disabled>
                    </div>
                </div>
                <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Admin Name :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" id="inputClientName" value="{{admin_name}}" disabled>
                    </div>
                </div>
                <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Admin ID :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" id="inputClientName" value="{{admin_id}}" disabled>
                    </div>
                </div>
                <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Email ID :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" value="{{admin_email_id}}" disabled>
                    </div>
                </div>
                <!-- <div class="row g-3 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Password :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="passwords"
                            id="password" value="{{current_password}}">
                    </div>
                    <div class="col-md-1 text-left" style="margin-left: -62px;">
                        <div>
                            <button id="search-button" type="button" class="btn btn-primary"  (mousedown)="showPassword = !showPassword" (mouseup)="showPassword = !showPassword"
                                style="height: 38px !important; background-color: #174A84; border-color: #174A84 ;">
                                <mat-icon alt="show" *ngIf="showPassword ==false" (click)="showPassword = !showPassword"
                                    [class.hide]="showPassword">visibility</mat-icon>
                                <mat-icon alt="hide" *ngIf="showPassword ==true" (click)="showPassword = !showPassword"
                                    [class.hide]="!showPassword">visibility_off</mat-icon>
                            </button>
                        </div>
                    </div> -->

                <!-- </div> -->

                <div class="row g-3 mt-10 mb-20">
                    <div class="col-md-2 text-left">
                    </div>
                    <div class="col-md-3 text-left ">
                        <div (click)="openSetPassword(1);" title="Set New Password"
                            style="text-decoration: underline;  color: #174A84;cursor: pointer;">Set New Password</div>
                    </div>

                    <!-- <div class="col-md-2 text-center">
                        <mat-icon class="close-icon " color="warn" style="cursor: pointer;margin-right: 45px;" title="close"
                            *ngIf="setflag==1" (click)="openSetPassword(0);">close</mat-icon>
                    </div> -->
                </div>

                <div class="row g-3 mt-10" *ngIf="setflag==1">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Enter Current Password :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input [type]="showCurrentPasswordOnPress ? 'text' : 'password'" class="form-control"
                            placeholder="Enter Current Password" name="current_password" id="current_password"
                            required #current_password="ngModel" ngModel name="current_password"
                            [class.is-invalid]="current_password.invalid && current_password.touched">
                        <div *ngIf="current_password.errors &&(current_password.invalid && current_password.touched)">
                            <small class="text-danger" *ngIf="current_password.errors"> Current Password Is Required </small>
                        </div>
                    </div>
                    <div class="col-md-1 text-left" style="margin-left: -62px;">
                        <div >
                            <button id="search-button" type="button" class="btn btn-primary" (mousedown)="showCurrentPasswordOnPress = !showCurrentPasswordOnPress"
                            (mouseup)="showCurrentPasswordOnPress = !showCurrentPasswordOnPress"
                                style="height: 38px !important; background-color: #174A84; border-color: #174A84 ;">
                                <mat-icon alt="show" [class.hide]="showCurrentPasswordOnPress"
                                    *ngIf="showCurrentPasswordOnPress ==false">visibility</mat-icon>
                                <mat-icon  alt="hide" [class.hide]="!showCurrentPasswordOnPress"
                                    *ngIf="showCurrentPasswordOnPress ==true">visibility_off</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row g-3 mt-10" *ngIf="setflag==1">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Enter Password :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input [type]="showPasswordOnPress ? 'text' : 'password'" class="form-control"
                            placeholder="Enter Password" name="new_password" id="new_password"
                            required #new_password="ngModel" ngModel name="new_password"
                            [class.is-invalid]="new_password.invalid && new_password.touched">
                        <div *ngIf="new_password.errors &&(new_password.invalid && new_password.touched)">
                            <small class="text-danger" *ngIf="new_password.errors"> Password Is Required </small>
                        </div>
                    </div>
                    <div class="col-md-1 text-left" style="margin-left: -62px;">
                        <div >
                            <button id="search-button" type="button" class="btn btn-primary" (mousedown)="showPasswordOnPress = !showPasswordOnPress"
                            (mouseup)="showPasswordOnPress = !showPasswordOnPress"
                                style="height: 38px !important; background-color: #174A84; border-color: #174A84 ;">
                                <mat-icon alt="show" [class.hide]="showPasswordOnPress"
                                    *ngIf="showPasswordOnPress ==false">visibility</mat-icon>
                                <mat-icon alt="hide" [class.hide]="!showPasswordOnPress"
                                    *ngIf="showPasswordOnPress ==true">visibility_off</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row g-3 mt-10" *ngIf="setflag==1">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Re-enter Password :</label>
                    </div> 
                    <div class="col-md-4 text-right">
                        <input [type]="showPasswordOnReEnter ? 'text' : 'password'" class="form-control"
                            placeholder="Enter Re-enter Password" name="re_new_password" id="re_new_password" required #re_new_password="ngModel" ngModel name="re_new_password"
                            [class.is-invalid]="re_new_password.invalid && re_new_password.touched">
                            <div *ngIf="re_new_password.errors &&(re_new_password.invalid && re_new_password.touched)" class="text-left">
                                <small class="text-danger" *ngIf="re_new_password.errors">Re-enter  Password Is Required </small>
                            </div>
                    </div>
                    <div class="col-md-1 text-left" style="margin-left: -62px;">
                        <div >
                            <button id="search-button" type="button" class="btn btn-primary" (mousedown)="showPasswordOnReEnter = !showPasswordOnReEnter"
                            (mouseup)="showPasswordOnReEnter = !showPasswordOnReEnter"
                                style="height: 38px !important; background-color: #174A84; border-color: #174A84 ;">
                                <mat-icon alt="show" [class.hide]="showPasswordOnReEnter"
                                    *ngIf="showPasswordOnReEnter ==false">visibility</mat-icon>
                                <mat-icon alt="hide" [class.hide]="!showPasswordOnReEnter"
                                    *ngIf="showPasswordOnReEnter ==true">visibility_off</mat-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row mb-20" style="margin-top:20px ;" *ngIf="setflag==1">
                    <mat-card-actions>
                        <div class="col-md-6 text-right"  *ngIf="myInfo.value.re_new_password =='' || myInfo.value.new_password =='' || myInfo.value.current_password==''">
                           
                            <button mat-raised-button type="submit" class="addButton mr-10" [disabled]="myInfo.invalid" disabled style="opacity: 0.5;">Save </button>
                            <button mat-raised-button (click)="openSetPassword(0);">Cancel </button>
                        </div>
                        <div class="col-md-6 text-right"  *ngIf="myInfo.value.re_new_password !='' && myInfo.value.new_password!='' && myInfo.value.current_password !='' ">
                            <button mat-raised-button type="submit" class="addButton mr-10">Save </button>
                            <button mat-raised-button (click)="openSetPassword(0);">Cancel </button>
                        </div>
                    </mat-card-actions>
                </div>

            </div>
        </form>

    </mat-card-content>

</mat-card>