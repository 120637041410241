import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ApiservicesService } from '../services/apiservices.service';
import { AuthserviceService } from '../services/authservice.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  status: any;
  message: any;
  constructor(private form: FormBuilder, private apicall: ApiservicesService, private router: Router, private toastr: ToastrService, private authService: AuthserviceService) { }

  usersDetails: any;
  error: any;
  ngOnInit(): void {
    if (this.authService.IsLoggedIn()) {
      window.location.href = "#/dashboard";
    }
  }


  getLoginDetails(data: any) {
    const dataa = { admin_id: data.username, password: data.password };
    this.apicall.checklogin(dataa).subscribe(params => {
      this.usersDetails = params.body;
      this.usersDetails = JSON.parse(JSON.stringify(this.usersDetails));
      this.status = this.usersDetails["status"];
      this.message = this.usersDetails["message"];
      if (this.usersDetails["result"]) {
        localStorage.setItem("user_token", this.usersDetails["result"]["jwttoken"]);
        let encrdata: string = btoa(this.usersDetails["result"]["admin_name"]);
        localStorage.setItem("userName", encrdata);
        let idencrdata: string = btoa(this.usersDetails["result"]["id"]);
        localStorage.setItem("id", idencrdata);
        this.error = "";
        this.toastr.success(this.message);
       setTimeout(function () { window.location.reload(); }, 1000);
      }else if(this.status == 301) {
        this.toastr.warning(this.message);
      } else if(this.status == 408) {
        this.toastr.error(this.message);
      }else{
        this.toastr.error(this.message);
      }
      // },
      // (err) =>{
      //   if(err.status === 401){
      //     this.error = "Invalid Username or Password  !!";
      //   }else if(err.status === 500){
      //     this.error = "Internal Server Error";
      //   }      
    });


  }

}
