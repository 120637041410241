<mat-card class="example-card">

    <mat-card-header>
        <div class="row" style="margin-top:20px ;  width: 100%;">
            <h2 class="hr-lines">Call Logs Details</h2>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="example-container mt-15">
            <div class="row g-4">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Call_ID :</label>
                </div>
                <div class="col-md-2 text-left">
                    <span for="inputBillTo" class="order-data"> {{id}} </span>
                </div>
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Call_date :</label>
                </div>
                <div class="col-md-2 text-left">
                    <span for="inputBillTo" class="order-data">{{call_date}} </span>
                </div>
                <div class="col-md-1 text-left">
                    <label for="inputBillTo" class="form-label">Status :</label>
                </div>
                <div class="col-md-2 text-left" *ngIf="api_status == 200">
                    <span for="inputBillTo" class="order-data">OK ({{api_status}}) </span>
                </div>
                <div class="col-md-2 text-left" *ngIf="api_status != 200">
                    <span for="inputBillTo" class="order-data">Error ({{api_status}}) </span>
                </div>
                <div class="col-md-1 text-left">

                    <button mat-raised-button routerLink="/APILogs">Cancel </button>
                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">API_Name :</label>
                </div>
                <div class="col-md-6 text-left">
                    <span for="inputBillTo" class="order-data"> {{api_name}} </span>
                </div>                
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Ip address :</label>
                </div>
                <div class="col-md-4 text-left">
                    <span for="inputBillTo" class="order-data"> {{ip_address}} </span>
                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Input Parameters :</label>
                </div>
                <div class="col-md-6 text-left">
                    <span for="inputBillTo" class="order-data" style="word-break: break-all;"> {{input_parameters}} </span>
                </div>
            </div>
            <div class="row g-4 mt-10">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label" >API Response :</label>
                </div>
                <div class="col-md-6 text-left">
                    <span for="inputBillTo" class="order-data" > {{api_response}} </span>
                </div>
            </div>


            <!-- <div class="row" style="margin-top:20px ;">
                <mat-card-actions>


                    <div class="col-md-3 text-left">

                        <button mat-raised-button routerLink="/APILogs">Cancel </button>
                    </div>
                </mat-card-actions>
            </div> -->

        </div>
    </mat-card-content>
</mat-card>