<mat-card class="example-card">
    <mat-card-header>
        <span class="label">Invoice</span>
        <span class="example-spacer"></span>
    </mat-card-header>
    <div class="breakline"></div>
    <mat-card-content>
        <div class="example-container mt-10">
            <div class="row g-3">
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Client Name :</label>
                </div>
                <div class="col-md-3 text-left">
                    <input type="text" class="form-control" id="inputClientName" value="{{client_name}}" disabled>
                </div>
                <div class="col-md-2 text-left">
                    <label for="inputBillTo" class="form-label">Partner Name :</label>
                </div>
                <div class="col-md-3 text-left" *ngIf="partner_name !== null">
                    <input type="text" class="form-control" id="inputClientName" value="{{partner_name}}" disabled>
                </div>
                <div class="col-md-3 text-left" *ngIf="partner_name == null">
                    <input type="text" class="form-control" id="inputClientName" value="-" disabled>
                </div>
                <div class="col-md-1 "></div>
                <div class="col-md-1 ">
                    <button mat-raised-button routerLink="/Invoice">Cancel</button>
                </div>
            </div>
            <h2 class="hr-lines" style="margin-top:20px ;">Apps</h2>
            <table class="table  mt-25">
                <thead>
                    <tr>
                        <th scope="col" style="border-bottom-color: inherit;">Sl No
                        </th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            App ID</th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            App Name</th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            Quantity</th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            Price type</th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            Price</th>
                        <th scope="col" style="border-bottom-color: inherit;">
                            Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let invoice of invoicedataresult; let i=index;">
                        <td>{{i+1}}</td>
                        <td class="title">{{invoice.app_id}} </td>
                        <td>{{invoice.app_name}}</td>
                        <td *ngIf="invoice.quantity !== null">{{invoice.quantity}}</td>
                        <td *ngIf="invoice.quantity == null">0</td>
                        <td>{{invoice.price_type}}</td>
                        <td *ngIf="invoice.price_type == 'Volumetric'|| invoice.price_type == 'volumetric'">
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-md-2">{{invoice.vol_price1}}</div>
                                <div class="col-md-6 text-left">(0-100)</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-md-2">{{invoice.vol_price2}}</div>
                                <div class="col-md-6 text-left">(101-500)</div>
                            </div>
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-md-2">{{invoice.vol_price3}}</div>
                                <div class="col-md-6 text-left">(101-500)</div>
                            </div>
                        </td>
                        <td *ngIf="invoice.price_type === 'Fixed'|| invoice.price_type === 'fixed'">
                            <div class="row">
                                <div class="col-md-4"></div>
                                <div class="col-md-2" *ngIf="invoice.fixed_price !== null">{{invoice.fixed_price}}</div>
                                <div class="col-md-2" *ngIf="invoice.fixed_price == null">0</div>
                            </div>
                        </td>
                        <td *ngIf="invoice.amount !== null">{{invoice.amount}}</td>
                        <td *ngIf="invoice.amount == null">0</td>
                    </tr>
                </tbody>
            </table>
            <div class="row g-3 mt-25">
                <div class="col-md-2 text-left ">
                    <button mat-raised-button style="background-color: #1ABB9C; color: #ffffff;"
                        (click)="invoice_detailpdf();">Generate Invoice
                    </button>
                </div>
                <div class="col-md-3 text-left"></div>
                <div class="col-md-3 text-left">
                    <div
                        style="font: normal normal 600 16px/25px Poppins; letter-spacing: 0px;color: #1ABB9C; opacity: 1;">
                        Total Amount ({{currency_type}}):

                        <span *ngIf="final_amount !== null"
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;  padding-left: 24px;">
                        {{final_amount}}</span>

                        <span *ngIf="final_amount == null"
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;  padding-left: 24px;">
                        0</span>
                    </div>
                </div>
                <!-- <div class="col-md-1 text-left" *ngIf="final_amount !== null">
                    <div
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;">
                        {{final_amount}}</div>

                </div>
                <div class="col-md-1 text-left" *ngIf="final_amount == null">
                    <div
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;">
                        0</div>

                </div> -->
                <div class="col-md-3 text-left">
                    <div
                        style="font: normal normal 600 16px/25px Poppins; letter-spacing: 0px;color: #1ABB9C; opacity: 1;">
                        Currency Type:
                        <span  *ngIf="currency_type !== null"
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1; padding-left: 24px;">
                        {{currency_type}}</span>
                        <span *ngIf="currency_type == null"
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1; padding-left: 24px;">
                        0</span>
                    </div>
                </div>
                <!-- <div class="col-md-2 text-left" *ngIf="currency_type !== null">
                    <div
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;">
                        {{currency_type}}</div>

                </div>
                <div class="col-md-2 text-left" *ngIf="currency_type == null">
                    <div
                        style="text-align: left;font: normal normal  16px/25px Poppins;letter-spacing: 0px;color: #174A84; opacity: 1;">
                        0</div>

                </div> -->

            </div>
            <!-- <div class="row g-3 mt-25">
                <div class="col-md-1 text-left ">
                    <button mat-raised-button routerLink="/Invoice">Close </button>

                </div>
            </div> -->
        </div>


    </mat-card-content>




</mat-card>