import { Component } from '@angular/core';
import { ApiservicesService } from '../services/apiservices.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-api-logs',
  templateUrl: './api-logs.component.html',
  styleUrls: ['./api-logs.component.css'],

})
export class ApiLogsComponent {
  pageInfo: any;
  applogData: any;
  datalength: boolean = false;
  start_date:any
  end_date:any

  pageSize = 10;
  pageIndex = 0;
  pageSizeOptions = [5, 10, 25, 100];
  pageEvent!: PageEvent;
  resultstatus: any;
  constructor(private apicall: ApiservicesService, private router: Router) { }
  ngOnInit(): void {
    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0

    }
    let today_date = new Date();
    // let today_date1 = new Date();
    var firstDay = new Date(today_date.getFullYear(), today_date.getMonth(), 1);
    this.start_date = formatDate(firstDay, 'dd-MMM-yyyy 00:00:00', 'en_US');
    this.end_date = formatDate(today_date, 'dd-MMM-yyyy 00:00:00', 'en_US');
    // this.start_date = formatDate(today_date.setDate(today_date.getDate() - 7), 'dd-MMM-yyyy', 'en-US');
    // this.end_date = formatDate(today_date1.setDate(today_date1.getDate() + 1), 'dd-MMM-yyyy', 'en-US');
    this.handlePageEvent(this.pageInfo)
  }

  handlePageEvent(e: PageEvent) {   
    this.pageInfo.pageEvent = e;  
    this.pageInfo.totalElements = e.length;
    this.pageInfo.pageSize = e.pageSize;
    this.pageInfo.pageIndex = e.pageIndex;
    let api = this.apiList(this.start_date,this.end_date);
  }

  apiRefreshData(data: any) {    
    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0,
      end_date:data.end_date,
      start_date:data.start_date
    }    
    this.filterApiLogs(this.pageInfo)
  }

  apiList(start_date:any,end_date:any) {
    let payload = {
      "end_date": end_date,
      "start_date": start_date,
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
    }
    this.apicall.api_call_log(payload).subscribe(params => {
      this.applogData = params.body;
      this.applogData = JSON.parse(JSON.stringify(this.applogData));
      this.resultstatus = this.applogData["status"];     
      if(this.resultstatus =='202'){
        this.datalength=true;
      }else{
      this.pageInfo.totalElements = this.applogData["result"].totalElements;
      this.applogData = this.applogData["result"]["data"];    
      this.datalength = false;
      }
    },(err) => {
        if (err.status === 0) {
          this.datalength = true;
        } else {
          alert(err.status);
        }
      });
  }

  filterApiLogs(data: any) {    
    if(data.end_date !== undefined){
    let payload = {
      "end_date": formatDate(data.end_date, 'dd-MMM-yyyy', 'en_US'),
      "start_date": formatDate(data.start_date, 'dd-MMM-yyyy', 'en_US'),
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
    }
    this.apicall.api_call_log(payload).subscribe(params => {
      this.applogData = params.body;
      this.applogData = JSON.parse(JSON.stringify(this.applogData));
      this.resultstatus = this.applogData["status"];     
      if(this.resultstatus =='202'){
        this.datalength=true;
      }else{
      this.pageInfo.totalElements = this.applogData["result"].totalElements;
      this.applogData = this.applogData["result"]["data"];
      this.datalength = false;
      this.start_date = formatDate(data.start_date, 'dd-MMM-yyyy', 'en_US')
      this.end_date = formatDate(data.end_date, 'dd-MMM-yyyy', 'en_US')
      }
    },
      (err) => {
        if (err.status === 0) {
          this.datalength = true;
        } else {
          alert(err.status);
        }
      });
    }
  }
  viewFilterApi(id: any) {
    this.router.navigate(['/VeiwAPILogs'],
      { queryParams: { id: id }, fragment: 'viewFilterApi' }
    )
  }
}
