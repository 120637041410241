<mat-card class="example-card">

    <mat-card-header>
        <div class="row" style="margin-top:20px ;  width: 100%;">
            <h2 class="hr-lines">Enter App Details</h2>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="example-container mt-10">
            <!-- <form  #editApp="ngForm" (submit)="editAppsdata(editApp.value)"  enctype="multipart/form-data"> -->
            <form id="edit-app-Form" name="editappForm" [formGroup]="editappForm" novalidate>
                <div class="row g-4">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App ID :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" required class="form-control" [value]="app_id" placeholder="Enter App ID"
                            disabled>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App Name :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" readonly="readonly" class="form-control" formControlName="app_name" placeholder="Enter App Name"
                            required [ngClass]="{'warn-border': editappForm.get('app_name').errors && 
                        (editappForm.get('app_name').touched || editappForm.get('app_name').dirty)}">
                        <div class="position-lg-absolute"
                            *ngIf="editappForm.get('app_name').invalid && (editappForm.get('app_name').dirty || editappForm.get('app_name').touched)">
                            <small *ngIf="editappForm.get('app_name').errors?.required" style="color: red;">
                                App name is required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Co-developer :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" required formControlName="codeveloper"
                            placeholder="Enter Co-developer name" readonly="readonly">
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Date of creation :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="datetime-local" class="form-control" formControlName="date_of_creation"
                            id="date_of_creation" readonly="readonly">
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Process Time :</label>
                    </div>
                    <div class="col-md-1 text-left">
                        <input type="number" class="form-control" formControlName="hours" readonly="readonly">
                    </div>
                    <div class="col-md-1 text-left">
                        <label for="inputBillTo" class="form-label">Hours</label>
                    </div>
                    <div class="col-md-1 text-left">
                        <input type="number" class="form-control" formControlName="minutes" maxlength="2" minlength="2" readonly="readonly">
                    </div>
                    <div class="col-md-1 text-left">
                        <label for="inputBillTo" class="form-label">Minutes</label>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Report Language :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <mat-checkbox *ngFor="let languages of languages; let j=index" (change)="getLanguages(languages.language,$event)" [checked]="languagess[j].isSelected">
                            {{ languages.language | uppercase }}
                        </mat-checkbox>
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Type of report :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <mat-checkbox *ngFor="let reports of reports; let k=index" (change)="getReports(reports.report_type,$event)" [checked]="reportess[k].isSelected">
                            {{ reports.report_type | uppercase }}
                        </mat-checkbox>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App Icon :</label>
                    </div>
                    <div class="col-md-2 text-left">
                        <img class="imageheight" id="blah" [src]="app_icon">
                    </div>
                    <div class="col-md-2">
                        <input type="file" class="form-control text-center" id="inputClientName" list="clientList" formControlName="appiconfile"
                            requiredname="appicon"  (change)="getimagedata($event)">
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Description :</label>
                    </div>
                    <div class="col-md-8 text-left">
                        <textarea class="form-control" required rows="2" style="resize:none;"
                            formControlName="app_description" placeholder="Description"></textarea>
                    </div>
                </div>
                <div class="row" style="margin-top:20px ;">
                    <mat-card-actions>

                        <div class="col-md-10 text-left">
                        </div>
                        <div class="col-md-2 text-left">
                            <button mat-raised-button class="addButton" type="submit" style="margin-right: 10px;"
                                (click)="editAppsdata()" >Update </button>
                            <button mat-raised-button routerLink="/Apps">Cancel </button>
                        </div>
                    </mat-card-actions>
                </div>
            </form>
        </div>
    </mat-card-content>

</mat-card>