import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiservicesService } from 'src/app/services/apiservices.service';

@Component({
  selector: 'app-view-orders',
  templateUrl: './view-orders.component.html',
  styleUrls: ['../../../styles.css', './view-orders.component.css']
})
export class ViewOrdersComponent implements OnInit {
  errorshow: boolean = false;
  client_name: any;
  client_id:any;
  app_name: any;
  order_date: any;
  order_status: any;
  partner_name:any;
  partner_id:any;
  Result_file: any;
  Php_log: any;
  Java_log: any;
  i:any;
  result_filename_array: any;
  constructor(private route: ActivatedRoute, private apicall: ApiservicesService) { }

  order_id: any;
  order_details: any;

  ngOnInit(): void {
    this.route.queryParams
      .subscribe(params => {
        this.order_id = params["orderid"];
        this.apicall.singleOrderDetails(this.order_id).subscribe(params => {
          this.order_details = params.body;
          this.order_details = JSON.parse(JSON.stringify(this.order_details));
          this.order_details = this.order_details["result"];
          this.client_name = this.order_details.client_name;
          this.client_id = this.order_details.client_id;
          this.app_name = this.order_details.app_name;
          this.order_date = this.order_details.order_date;
          this.order_status = this.order_details.order_status;
          this.partner_name = this.order_details.partner_name;
          this.partner_id = this.order_details.partner_id; 
          this.result_filename_array= this.order_details.result_filename_array;
          this.Result_file= this.order_details.result_filename_array.Result_file;
          this.Php_log= this.order_details.result_filename_array.Php_log;
          this.Java_log= this.order_details.result_filename_array.Java_log;
        },
          (err) => {
            if (err.status === 404) {
              if (err["error"]["status"] == 'false') {
                this.errorshow = true;
                this.order_details = [];
              }
            }
          })
      }
      );
  }

}
