import { Component } from '@angular/core';
import { AppComponent } from '../app.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.css']
})
export class SidenavComponent {
  routerLinkActive:any;
  constructor(public appdata:AppComponent,private routerr:Router){
    this.routerLinkActive = this.routerr.url;
  }

  

}
