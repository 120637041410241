import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MODALComponent } from '../modal/modal.component';
import { MatDialog } from '@angular/material/dialog';
import { ApiservicesService } from '../services/apiservices.service';
import { PageEvent } from '@angular/material/paginator';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-apps',

  templateUrl: './apps.component.html',
  styleUrls: ['../../styles.css', './apps.component.css']
})
export class AppsComponent implements OnInit {

  appdetails: any;
  datalength: any;
  pageInfo: any;
  pageSize: any;
  pageIndex: any;
  pageEvent!: PageEvent;
  apideleteListData: any;
  status: any;
  constructor(private router: Router, private _dialog: MatDialog, private apicall: ApiservicesService, private toastr: ToastrService) { }

  ngOnInit(): void {
    this.pageInfo = {
      pageSize: 10,
      totalElements: 0,
      totalPages: 0,
      pageIndex: 0

    }
    this.handlePageEvent(this.pageInfo)

  }

  handlePageEvent(e: PageEvent) {
    this.pageInfo.pageEvent = e;
    this.pageInfo.totalElements = e.length;
    this.pageInfo.pageSize = e.pageSize;
    this.pageInfo.pageIndex = e.pageIndex;
    this.getListApp();
  }
  getListApp() {
    let payload = {
      "page_index": this.pageInfo.pageIndex,
      "page_size": this.pageInfo.pageSize,
    }
    this.apicall.getAllApp(payload).subscribe(params => {
      this.appdetails = params.body;
      this.appdetails = JSON.parse(JSON.stringify(this.appdetails));
      this.status=this.appdetails["status"];     
    
      if (this.status === "202") {
    
        this.datalength = 0;
      } else {
        this.datalength = 1;
        this.pageInfo.totalElements = this.appdetails["result"].totalElements;
        this.appdetails = this.appdetails["result"]['data'];    
      }
    },
      (err) => {
        console.log(5230,err.status);
        
        if (err.status === 0) {
          this.datalength = 0;
        } else {

        }
      });
  }

  goApps(appid: any) {
    this.router.navigate(
      ['/edit-app'],
      { queryParams: { appid: appid }, fragment: 'editdata' }
    );
  }


  addApps() {
    this.router.navigate(['/add-app']);
  }

  // confirmdelete(){

  //   alert("Success");
  //   location.reload();

  // }

  openConfirmationDialog(data: any) {
    const dialogRef = this._dialog.open(MODALComponent, {
      disableClose: false
    });
    dialogRef.componentInstance.delete = "Delete App"
    dialogRef.componentInstance.confirmMessage = "Are you sure you want to delete?"
    dialogRef.componentInstance.button_label = " Yes, Delete it";
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let dataa = { app_id: data }
        this.apicall.deleteApp(dataa).subscribe(params => {
          this.apideleteListData = params.body;
          this.apideleteListData = this.apideleteListData["status"];
          if (this.apideleteListData == true) {
            this.toastr.success('App details has been deleted successfully!.');
            this.router.navigate(['/Apps']);
            setTimeout(function(){window.location.reload();}, 2000); 
            // location.reload();
          }
          else {
            this.toastr.error('App details has been unavailable to deleted!.');
            // this.toastr.error('everything is broken', 'Major Error', {
            //   timeOut: 3000,
            // });
          }
        },
          (err) => {
            alert(err);
          });
      }
      const dialogRef = null;
    });
  }
}