<mat-nav-list>
    <span class="p-2 admin_title">MGB India</span>
    <div class="logo row">
        <div class=" col-3">  <img src="https://for-uriwell.s3.ap-southeast-1.amazonaws.com/user_profile/image_1695708908721.png" alt="logos" class="pl-20 pr-100"></div>
        <div class=" col-7"> 
            <div>
                <span class=" admin_wel">Welcome,</span>
            </div>
       
            <div>
                <span class=" admin_desc">{{ appdata.username }}</span>
            </div>
       
        </div>
        <!-- <span class="p-2 admin_desc col-2">Admin Panel</span> -->
     
    </div>
 
    <!-- <mat-divider></mat-divider> -->
    <!-- <h2 matSubheader class="mt-2">Application</h2> -->

 
    <a mat-list-item routerLink="/dashboard" routerLinkActive="active"> 
        <mat-icon>dashboard</mat-icon><span class="title">Dashboard</span>
    </a>
    <a mat-list-item    routerLink="/clients" routerLinkActive="active"> 
        <mat-icon>group</mat-icon><span class="title">Clients</span>
    </a>
    <a mat-list-item    routerLink="/Apps" routerLinkActive="active"> 
        <mat-icon>shopping_cart</mat-icon><span class="title">Apps</span>
    </a>
    <a mat-list-item    routerLink="/Orders" routerLinkActive="active"> 
        <mat-icon>local_shipping</mat-icon><span class="title">Orders</span>
    </a>
    
    <a mat-list-item    routerLink="/APILogs" routerLinkActive="active"> 
        <mat-icon>reorder</mat-icon><span class="title">API Logs</span>
    </a>
    <a mat-list-item    routerLink="/Invoice" routerLinkActive="active"> 
        <mat-icon>receipt</mat-icon><span class="title">Invoice</span>
    </a>
    <a mat-list-item    routerLink="/MyInformation" routerLinkActive="active"> 
        <mat-icon>person_outline</mat-icon><span class="title">My Information</span>
    </a>
</mat-nav-list>