<mat-card class="example-card">
  <mat-card-header>
    <span class="label"> Add Clients</span>
    <span class="example-spacer"></span>
    <mat-card-actions>

    </mat-card-actions>

  </mat-card-header>
  <div class="breakline"></div>
  <mat-card-content>
    <div class="example-container" (click)="hidefilter()">
      <form #clientpartner="ngForm" (submit)="getFormdata(clientpartner.value)">
        <div class="row g-4">
          <div class="col-md-2">
            <label for="inputBillTo" class="form-label">Client Name :</label>
          </div>
          <div class="col-md-3 dropdown-content" id="myDropdown">
            <input type="text" class="form-control" id="client_name" list="clientList" placeholder="Enter Client Name"
              required #client_name="ngModel" [ngModel]="client_namee" name="client_name"
              [class.is-invalid]="client_name.invalid && client_name.touched"  (keyup)="filterFunction($event)" >
            <div *ngIf="client_name.errors &&(client_name.invalid && client_name.touched)">
              <small class="text-danger" *ngIf="client_name.errors"> Client Name Is Required </small>
            </div>
            <div class="content" #content (clickOutside)="hidefilter();">
              <a  *ngFor="let clientdata of clientdata" (click)="setData(clientdata.name)" style="cursor:pointer;"> {{ clientdata.name }}</a>
            </div>
          </div>
          <div class="col-md-2">
            <label for="inputBillTo" class="form-label">Partner Name :</label>
          </div>
          <div class="col-md-3">
            <input type="text" class="form-control" id="partner_name" list="clientList" placeholder="Enter Partner Name"
              #partner_name="ngModel" ngModel name="partner_name" (change)="billto();">
          </div>
        </div>
        <div class="row" style="margin-top: 12px;">
          <div class="col-md-2">
            <label for="inputBillTo" class="form-label">Client Currency :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control" #clientcurrency="ngModel" ngModel name="clientcurrency" required
              [class.is-invalid]="clientcurrency.invalid && clientcurrency.touched">
              <option value=""> Select Currency </option>
              <option *ngFor="let clientcurrencyy of clientcurrencyy" [disabled]="disabl" [value]="clientcurrencyy.value" [selected]="clientcurrencyy.value === clntcurrency">{{ clientcurrencyy.value}}
              </option>
            </select>

            <div *ngIf="client_name.errors &&(clientcurrency.invalid && clientcurrency.touched)">
              <small class="text-danger" *ngIf="client_name.errors"> Client Currency Is Required </small>
            </div>
          </div>
          
          <span *ngIf="flag == true" class="col-md-7">
            <div class="row" style="margin-left:1%;">
              <div class="col-md-2" *ngIf="partner_name.value !==''">
                <label for="inputBillTo" class="form-label">Bill To :</label>
              </div>
              <div class="col-md-3" *ngIf="partner_name.value !==''">
                <mat-radio-group aria-label="Select an option" #bill_to="ngModel" ngModel name="bill_to" (click)="showcurrency($event);">
                  <mat-radio-button value="client">Client</mat-radio-button>
                  <mat-radio-button value="partner">Partner</mat-radio-button>
                </mat-radio-group>
                <div *ngIf="partner_error">
                  <small class="text-danger"> Atleast One Bill To is required </small>
                </div>
              </div>
            </div>
          </span>
        </div>
        <div class="row" *ngIf="currencypartnerdata" style="margin-top: 12px;">
          <div class="col-md-5">
          </div>
          <div class="col-md-2">
            <label for="inputBillTo" class="form-label">Partner Currency :</label>
          </div>
          <div class="col-md-3">
            <select class="form-control" #partnercurrency="ngModel" ngModel name="partnercurrency" required
              [class.is-invalid]="partnercurrency.invalid && partnercurrency.touched && currencypartnerdata">
              <option value=""> Select Currency </option>
              <option *ngFor="let clientcurrencyy of clientcurrencyy" [value]="clientcurrencyy.value">{{ clientcurrencyy.value}}
              </option>
            </select>

            <div *ngIf="client_name.errors &&(partnercurrency.invalid && partnercurrency.touched && currencypartnerdata)">
              <small class="text-danger" *ngIf="client_name.errors"> Partner Currency Is Required </small>
            </div>
          </div>
        </div>

        <div class="row" style="margin-top:20px ;">
          <mat-card-actions>

            <div class="col-md-10">
            </div>
            <div class="col-md-2" *ngIf="client_name.invalid; then disabled; else nothing"></div>
            <ng-template #disabled>
              <button mat-raised-button class="addButton" disabled style="margin-right: 10px;">Save </button>
            </ng-template>
            <ng-template #nothing>
              <button mat-raised-button class="addButton" style="margin-right: 10px;">Save </button>
            </ng-template>
            <button mat-raised-button routerLink="/clients">Cancel </button>
          </mat-card-actions>
        </div>
      </form>
    </div>
  </mat-card-content>

</mat-card>