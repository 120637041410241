import { Component, OnInit } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-apps',
  templateUrl: './add-apps.component.html',
  styleUrls: ['../../../styles.css', './add-apps.component.css']
})
export class AddAppsComponent implements OnInit {

  imagefile!: File;
  localUrl: any = "https://for-uriwell.s3.ap-southeast-1.amazonaws.com/user_profile/image_1696937940178.png";
  apiAddListData: any;
  message: any;
  process_time:any;
  constructor(private router: Router, private apicall: ApiservicesService, private toastr: ToastrService) { }
  languages = [
    { language: "EN", value: "en" },
    { language: "KR", value: "kr" },
    { language: "CN", value: "cn" },
    { language: "JP", value: "jp" }   
  ];
  report_type = [
    { report_type: "ORIGINAL", value: "original" },
    { report_type: "WHITELABEL", value: "whitelabel" },
    { report_type: "COBRANDED", value: "cobranded" },
    { report_type: "TEXT", value: "text" }   
  ];
  selectedLanguage: any = [];
  selectedReportType: any = [];
  ngOnInit(): void {

  }
  getLanguages(paraname: any, event: any) {
    if (event.checked==true) {
      this.selectedLanguage.push(paraname);
    } else {
      let index = this.selectedLanguage.indexOf(paraname)
      this.selectedLanguage.splice(index, 1);
    }
  }

  getreport(paraname: any, event: any) {    
    if (event.checked==true) {
      this.selectedReportType.push(paraname);
    } else {
      let index = this.selectedReportType.indexOf(paraname)
      this.selectedReportType.splice(index, 1);
    }
  }

  getimagedata(event: any) {


    let fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      this.imagefile = fileList[0];

      const reader = new FileReader();
      reader.onload = e => this.localUrl = reader.result;

      reader.readAsDataURL(this.imagefile);

    }

  }

  addAppsdata(data: any) {
    console.log(data);
   
  
      this.process_time= ('0' + data.process_hours_time).slice(-2) + ":" + ('0' + data.process_minutes_time).slice(-2)
    
   
  
    let formData: FormData = new FormData();

    formData.append("app_id", data.appid);
    formData.append("app_name", data.appname);
    formData.append("codeveloper", data.codeveloper);
    if (data.dateofcreation != '') {
      formData.append("date_of_creation", formatDate(data.dateofcreation, 'dd-MMM-yyyy HH:mm:ss', 'en_US'));
    }
    formData.append("app_description", data.description);
    formData.append("process_time", this.process_time);
    formData.append("report_lang", this.selectedLanguage)
    formData.append("report_type", this.selectedReportType)
    console.log("4545",this.selectedReportType);
    
    if (this.imagefile) {
      formData.append('app_icon', this.imagefile, this.imagefile.name);
    }
    this.apicall.addApps(formData).subscribe(param => {
      this.apiAddListData = param.body;
      this.message = this.apiAddListData["message"];
      this.apiAddListData = this.apiAddListData["status"];
      if (this.apiAddListData == true) {
        this.toastr.success(this.message);
        this.router.navigate(['/Apps']);
      }
      else if (this.apiAddListData == 401) {
        this.toastr.error(this.message);
      } else if (this.apiAddListData == 410) {
        this.toastr.warning(this.message);
      }
      else if (this.apiAddListData == 402) {
        this.toastr.warning(this.message);
      }
      else if (this.apiAddListData == 406) {
        this.toastr.warning(this.message);
      } else if (this.apiAddListData == 406) {
        this.toastr.warning(this.message);
      } else if (this.apiAddListData == 303) {
        this.toastr.warning(this.message);
      } else if (this.apiAddListData == 403) {
        this.toastr.error(this.message);
      }else if (this.apiAddListData == 407) {
        this.toastr.error(this.message);
      }else if (this.apiAddListData == 600) {
        this.toastr.error(this.message);
      }else if (this.apiAddListData == 300) {
        this.toastr.error(this.message);
      }else {
        this.toastr.error(this.message);
      }

    });

  }



}
