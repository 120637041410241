<mat-toolbar>
  <mat-toolbar-row class="jstify-content-between ">
    <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon"
      (click)="toggleSidebar()">
      <mat-icon class="menus">toc</mat-icon>
    </button>
    <span class="example-spacer"></span>


    <div class="row">
      <!-- <div class="demo-section menus col-3">
        <mat-icon matBadge="15" matBadgeColor="warn">notifications_none</mat-icon>
      </div>
      <div class=" col-3">
        <img src="https://for-uriwell.s3.ap-southeast-1.amazonaws.com/user_profile/image_1695708908721.png" alt=""
          class="user-image mr-1 p-1">
      </div> -->
      <ul class="col-6">
        <li>
          <button mat-button [matMenuTriggerFor]="menu" class="user mt-2 d-flex align-item-center">

            <span class="user_name ">{{ appdata.username }}</span>
            <mat-icon style="text-align: end;">keyboard_arrow_down</mat-icon>

          </button>
          <mat-menu #menu="matMenu" class="info">
            <button mat-menu-item style="text-align: left;font: normal normal normal 14px/21px Poppins;letter-spacing: 0px;color: #174A84;opacity: 1;" routerLink="/MyInformation">
              <mat-icon style="color: #174A84;">exit_to_app</mat-icon>
              <span> My Information</span>
            </button>
            <button mat-menu-item class="info" routerLink="/logout">
              <mat-icon style="color: #174A84;">exit_to_app</mat-icon>
              <span>Logout</span>
            </button>
          </mat-menu>
        </li>
      </ul>

    </div>
  </mat-toolbar-row>
</mat-toolbar>