<body style="max-width:450px; height: 200px;">
   
        <div class="container" >
            <div class="row d-flex justify-content-center" >
                <div class="text-center titlestyle " >
                    <h1 md-dialog-title class=" mt-10 mb-20">{{delete}}</h1>
                </div>
     

                    
                <div md-dialog-content class="text-center " style="margin: 30px;">{{confirmMessage}}</div>
                <div md-dialog-actions class="text-right">

                    <button mat-raised-button class="button-danger" style="margin-right: 10px;" (click)="dialogRef.close(true)">{{button_label}}</button>
                    <button mat-raised-button (click)="dialogRef.close(false)">Cancel</button>
                <!-- <button md-button style="color: #fff;background-color: #153961;" (click)="dialogRef.close(true)">Confirm</button>
                <button md-button (click)="dialogRef.close(false)">Cancel</button> -->
                </div>

            </div>
        </div>

</body>
