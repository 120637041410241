<!-- <!DOCTYPE html>
<html>
<head>
<link href='https://fonts.googleapis.com/css?family=Lexend Deca' rel='stylesheet'>
</head>
<body>
  
        <!-<h2> WELCOME {{ appdata.username }} </h2> ->

  </body>
</html> -->

<mat-card class="example-card">

  <mat-card-header>
    <span class="label">Statistics</span>

  </mat-card-header>
  <div class="breakline"></div>
  <mat-card-content>
    <div class="example-container mt-10">
      <form id="invoice-filter" name="filterInvoiceForm" [formGroup]="filterInvoiceForm" novalidate>
        <div class="row g-3">
          <div class="col-md-3 text-left"></div>
          <div class="col-md-6" *ngIf="errorfilterinvoiceshow==true">
            <p class="nodatafound"> No Data Found... </p>
          </div>
          <div class="col-md-6" *ngIf="errorfilterinvoiceshow==false">
            <canvas id="canvas">{{chart}}</canvas>

          </div>
          <div class="col-md-1" style="text-align: -webkit-right;"
            *ngIf="errorfilterinvoiceshow==false && sumNumber!=0">

            <div style=" height: 20px; width: 30px; background-color: #174A84;"></div>
          </div>

          <div class="col-md-1" *ngIf="errorfilterinvoiceshow==false && sumNumber!=0 ">

            <div>Total Count: {{sumNumber}} </div>
          </div>
        </div>
        <div class="row g-3 mt-15">
          <div class="col-md-2 text-left"></div>
          <div class="col-md-2 ">
            <select class="form-control" formControlName="client_id"
              (change)="partnerWithClient(); fetchAppNameForClient();">
              <option Value="" [disabled]="true">Select Client</option>
              <option *ngFor="let client of clientdata" [ngValue]="client">{{client.client_name}}</option>
            </select>
          </div>
          <div class="col-md-2 text-left">
            <select class="form-control" formControlName="partner_id" (change)="fetchAppName();">
              <option Value="" [disabled]="true">Select Partner</option>
              <option *ngFor="let partner of partnerdata" [ngValue]="partner">{{partner.partner_name}}
            </select>
          </div>
          <div class="col-md-2 text-left">
            <select class="form-control" formControlName="app_id">
              <option Value="" [disabled]="true">Select App</option>
              <option *ngFor="let app of appdata" [ngValue]="app">{{app.app_name}}</option>
            </select>
          </div>
          <div class="col-md-2 text-left">
            <select class="form-control" (change)="customDate(duration.value)" formControlName="duration" #duration>
              <!-- <option Value="" [disabled]="true">Duration</option> -->
              <option value="this_month">This Month</option>
              <option value="last_day">Last 30 Days</option>
              <option value="one_year">1 Year</option>
              <option value="custom">Custom</option>
            </select>
          </div>
          <div class="col-md-2 text-left" *ngIf="customdateFlag == false">
            <button mat-raised-button class="addButton" (click)="invoiceFilter();">Filter </button>
          </div>
        </div>
        <div class="row g-3 mt-15">
          <div class="col-md-2 text-left"></div>
          <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
            <label for="inputBillTo" class="form-label">Start Date :</label>
          </div>
          <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
            <input type="date" class="form-control" #start_date formControlName="start_date" id="inputClientName"
              (change)="checkvaluestartdate($event);">
          </div>
          <div class="col-md-1 text-left" *ngIf="customdateFlag == true">
            <label for="inputBillTo" class="form-label">End Date :</label>
          </div>
          <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
            <input type="date" class="form-control" #end_date formControlName="end_date" id="inputClientName"
              (change)="checkvalueenddate($event);">
          </div>
          <div class="col-md-2 text-left" *ngIf="customdateFlag == true">
            <div *ngIf="startdate === '' || enddate === ''">
              <button mat-raised-button class="addButton" disabled style="opacity: 0.5;">Filter </button>
            </div>
            <div *ngIf="startdate !='' && enddate !=''">
              <button mat-raised-button class="addButton" (click)="invoiceFilter();">Filter </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-card-content>
</mat-card>