import { Component } from '@angular/core';
import { Router } from '@angular/router';
import * as pdfMake from "pdfmake/build/pdfmake";
import * as pdfFonts from "pdfmake/build/vfs_fonts";
import { ApiservicesService } from 'src/app/services/apiservices.service';
import { ToWords } from 'to-words';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;
@Component({
  selector: 'app-view-statistics',
  templateUrl: './view-statistics.component.html',
  styleUrls: ['./view-statistics.component.css']
})
export class ViewStatisticsComponent {
  toWords = new ToWords({
    localeCode: 'en-IN',
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
    }
  });
  queryParams: any;
  invoicedata: any;
  invoicedatastatus: any;
  client_name: any;
  partner_name: any;
  final_amount: any;
  invoicedataresult: any;
  amount: any;
  dataarray: any[any]
  currency_type: any;
  clientpartnerdata: any;
  bill_to: any;
  client_company_address: any;
  client_company_name: any;
  client_email: any;
  totalwords: any;
  imageUrl: any;
  // getBase64ImageFromURL: any;
  constructor(private router: Router,
    private apicall: ApiservicesService,) { }
  ngOnInit(): void {
    this.queryParams = history.state;
    this.invoice_detail();
    this.generate_invoice();   
  }

  invoice_detail() {
    let payload = {
      "month": this.queryParams.month,
      "year": this.queryParams.year,
      "client_id": this.queryParams.client_id,
      "partner_id": this.queryParams.partner_id,
      "app_id": this.queryParams.app_id,
    }
    this.apicall.invoice_detail(payload).subscribe(params => {
      this.invoicedata = params.body;
      this.invoicedatastatus = this.invoicedata["status"];
      this.invoicedata = JSON.parse(JSON.stringify(this.invoicedata));
      this.invoicedataresult = this.invoicedata['result']['data'];
      for (let j = 0; j < this.invoicedataresult.length; j++) {
        this.client_name = this.invoicedataresult[0]['client_name']
        this.partner_name = this.invoicedataresult[0]['partner_name']
        this.final_amount = this.invoicedataresult[0]['final_amount']
        this.currency_type = this.invoicedataresult[0]['currency_type']
      }
    }
    )
  }

  invoice_detailpdf() {
    let payload = {
      "month": this.queryParams.month,
      "year": this.queryParams.year,
      "client_id": this.queryParams.client_id,
      "partner_id": this.queryParams.partner_id,
      "app_id": this.queryParams.app_id,
    }
    this.apicall.invoice_detail(payload).subscribe(params => {
      this.invoicedata = params.body;
      this.invoicedatastatus = this.invoicedata["status"];
      this.invoicedata = JSON.parse(JSON.stringify(this.invoicedata));
      this.invoicedataresult = this.invoicedata['result']['data'];
      this.export(this.invoicedataresult);
    }
    )
  }

  generate_invoice() {
    let payload;
    if (this.queryParams.partner_id != null) {
      payload = {
        "client_id": this.queryParams.client_id,
        "partner_id": this.queryParams.partner_id
      }
    } else {
      payload = {
        "client_id": this.queryParams.client_id
      }
    }
    this.apicall.generate_invoice(payload).subscribe(params => {
      this.clientpartnerdata = params.body;
      this.clientpartnerdata = this.clientpartnerdata['result'];
    })
  }
  
  public async export(data: any): Promise<void> {
    this.bill_to = this.clientpartnerdata['client_company_name'];
    this.client_company_address = this.clientpartnerdata['client_company_address'];
    this.client_company_name = this.clientpartnerdata['client_company_name'];
    this.client_email = this.clientpartnerdata['client_email'];
    this.currency_type = data[0]['currency_type'];
    this.imageUrl=this.clientpartnerdata['invoice_image'];
    let valuenotfound = '-', final_amount;
    if (data[0]['final_amount'] == null) {
      final_amount = '0'
      this.totalwords = this.toWords.convert(0);
    } else {
      final_amount = data[0]['final_amount'];
      this.totalwords = this.toWords.convert(final_amount);
    }
    this.dataarray = []
    let j = 0;
    for (let i = 0; i < data.length; i++) {
      this.dataarray[j] = []

      if (data[i].price_type == 'volumetric' || data[i].price_type == 'Volumetric') {
        let quantity, amount
        if (data[i].amount == null) {
          amount = '0';
        } else {
          amount = data[i].amount;
        }
        if (data[i].quantity == null) {
          quantity = '0';
        } else {
          quantity = data[i].quantity;
        }
        this.dataarray[j].push(
        
          // 20, 0, 0, 0
          { text: i + 1, style: 'textfont', margin: [20, 10, 10, 10]  },
          { text: data[i].app_id, style: 'textfont', margin: [0, 10, 0, 10] },
          { text: data[i].app_name, style: 'textfont', margin: [0, 10, 0, 10] },
          { text: quantity, style: 'textfont', margin: [0, 10, 0, 10] },
          { text: data[i].price_type, style: 'textfont', margin: [0, 10, 0, 10] },
          {
            text: [
              { text: data[i].vol_price1 + '(0-100) \n', style: 'textfont'  },
              { text: data[i].vol_price2 + '(101-500) \n', style: 'textfont' },
              { text: data[i].vol_price3 + '(500+)', style: 'textfont'},

            ],         
            margin: [0, 10, 0, 10]
          },
          { text: amount, style: 'textfont', margin: [0, 10, 0, 10] }
        )
      } else {
        let quantity, amount ,fixed_price
        if (data[i].amount == null) {
          amount = '0';
        } else {
          amount = data[i].amount;
        }
        if (data[i].quantity == null) {
          quantity = '0';
        } else {
          quantity = data[i].quantity;
        }
        if (data[i].fixed_price == null) {
          fixed_price = '0';
        } else {
          fixed_price = data[i].fixed_price;
        }
        this.dataarray[j].push(
          { text: i + 1, style: 'textfont', margin: [20, 10, 10, 10]},
          { text: data[i].app_id, style: 'textfont', margin: [0, 10, 0, 10]},
          { text: data[i].app_name, style: 'textfont', margin: [0, 10, 0, 10]},
          { text: quantity, style: 'textfont', margin: [0, 10, 0, 10]},
          { text: data[i].price_type, style: 'textfont', margin: [0, 10, 0, 10]},
          { text: fixed_price, style: 'textfont', margin: [0, 10, 0, 10]},
          { text: amount, style: 'textfont', margin: [0, 10, 0, 10]}
        )
      }
      j++;
    }
    pdfMake.createPdf({
      content: [
        {
          columns: [
            [              
              {             
                "image":"data:image/png;base64,"+this.imageUrl,
                width: 150
            
              }
            ],
            [
              {
                text: 'INVOICE',
                fontSize: 8,
                bold: true,
                alignment: 'right',
                margin: [0, 0, 0, 3]
              },
              {
                text: '(ORIGINAL FOR RECIPIENT)',
                fontSize: 8,
                alignment: 'right',
                margin: [0, 0, 0, 3]
              },
              {
                text: 'TAX SCHEME : GST',
                fontSize: 8,
                alignment: 'right',
                margin: [0, 0, 0, 0]
              },
            ]
          ]
        },
        {
          columns: [
            [
              {
                text: [
                  { text: 'Bill To: ', bold: true, fontSize: 10 },
                  this.bill_to
                ],
                fontSize: 9,
                margin: [0, 20, 0, 3]
              },
              {
                text: [
                  { text: 'Trade Name: ', bold: true, fontSize: 10 },
                  this.client_company_name
                ],
                fontSize: 9,
                margin: [0, 0, 0, 3]
              },
              {
                text: [
                  { text: 'Address: ', bold: true, fontSize: 10 },
                  this.client_company_address
                ],
                fontSize: 9,
                margin: [0, 0, 0, 3]
              },
              {
                text: [
                  { text: 'GSTIN: ', bold: true, fontSize: 10 },
                  valuenotfound
                ],
                fontSize: 9,
                margin: [0, 0, 0, 3]
              },
              {
                text: [
                  { text: 'mobile: ', bold: true, fontSize: 10 },
                  valuenotfound
                ],
                fontSize: 9,
                margin: [0, 0, 0, 3]
              },
              {
                text: [
                  { text: 'Email: ', bold: true, fontSize: 10 },
                  this.client_email
                ],
                fontSize: 9,
                margin: [0, 0, 0, 20]
              }
            ],
            []
          ]
        },
        {
          table: {            
            widths: ['auto', '*', '*', '*', '*', '*', '*'],          
            body: [              
              [
                { text: 'S.No', style: 'textfont', bold: true, margin: [6, 10, 10, 10] ,border: [true, true, true, false], },
                { text: 'App ID', style: 'textfont', bold: true, margin: [0, 10, 0, 10],  border: [true, true, true, false], },
                { text: 'App Name', style: 'textfont', bold: true, margin: [0, 10, 0, 10],  border: [true, true, true, false], },
                { text: 'Quantity', style: 'textfont', bold: true, margin: [0, 10, 0, 10],  border: [true, true, true, false], },
                { text: 'Price type', style: 'textfont', bold: true , margin: [0, 10, 0, 10],  border: [true, true, true, false], },
                { text: 'Price', style: 'textfont', bold: true , margin: [0, 10, 0, 10] ,border: [true, true, true, false], },
                { text: 'Amount', style: 'textfont', bold: true, margin: [0, 10, 0, 10],  border: [true, true, true, false], }
              ],
            ]
          }
        },
        {
          table: {
            widths: ['auto', '*', '*', '*', '*', '*', '*'],
            body: this.dataarray
          }
        },
        {
          // text: "Total Amount (INR / $):  this.client_company_name",
          text: [
            { text: 'Total Amount (' + this.currency_type + '): ', bold: true, fontSize: 10 },
            final_amount
          ],
          bold: true,
          alignment: 'right',
          margin: [0, 10, 25, 10],
          fontSize: 9,
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                {
                  text: 'Amount in words : ' + this.totalwords + '',
                  bold: true,
                  fontSize: 10,
                  margin: [0, 5, 0, 5],
                  border: [true, true, true, false]
                }
              ]
            ]
          }
        },
        {
          table: {
            widths: ['*'],
            body: [
              [
                [
                  {
                    text: "Make all payments A/c payable to",
                    bold: true,
                    fontSize: 10,
                    margin: [0, 5, 0, 0],
                  },
                  {
                    text: "MyGenomeBox India Private Limited",
                    fontSize: 10,
                    margin: [0, 2, 0, 2],
                  },
                  {
                    text: "A/C No: 50200009713759",
                    fontSize: 10,
                    margin: [0, 2, 0, 2],
                  },
                  {
                    text: "IFSC: HDFC0002055",
                    fontSize: 10,
                    margin: [0, 0, 0, 5],
                  }
                ]
              ]
            ]
          }
        },
        {
          text: "This is a system generated Invoice.",
          fontSize: 8,
          margin: [0, 15, 0, 0],
        },
      ],
      footer: {
        columns: [
          [
            {
              text: [
                { text: 'MyGenomeBox India Private Limited', bold: true },
              ],
              fontSize: 9,
              margin: [40, 0, 0, 2],
            },
            {
              text: [
                { text: 'Address:', bold: true },
                'MyGenomeBox India Pvt. Ltd., 3rd Floor, AWFIS Space Solutions, ABIL Imperial, Rohan Seher Lane, Baner, Pune, Maharastra, India, 411045'
              ],
              fontSize: 9,
              margin: [40, 0, 0, 2],
            },
            {
              text: [
                { text: 'Corporate Identity No.:', bold: true },
                'U74999PN2018FTC180719'
              ],
              fontSize: 9,
              margin: [40, 0, 0, 2],
            },

          ],
          [
            {
              text: [
                { text: 'GSTIN:', bold: true },
                '27AAMCM2908G1ZD'
              ],
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 40, 2],
            },
            {
              text: [
                { text: 'mobile:', bold: true },
                '+91-63673-58509'
              ],
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 40, 2],
            },
            {
              text: [
                { text: 'Email:', bold: true },
                'a.goyal@mygenomebox.in'
              ],
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 40, 2],
            }
          ]
        ]
      },
      styles: {
        textfont: {
          fontSize: 9,
          alignment: 'center'
        },
        footer: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 40]
        },
        tableExample: {
          margin: [0, 5, 0, 15]
        }
      }
    }).download("Invoice.pdf");
  }
}

