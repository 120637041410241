<mat-card class="example-card">
  <mat-card-header>
    <span class="label">API Logs</span>
    <span class="example-spacer"></span>
  </mat-card-header>
  <div class="breakline"></div>
  <mat-card-content>
    <div class="example-container mt-10">

      <form #filterApi="ngForm" (submit)="apiRefreshData(filterApi.value)" enctype="multipart/form-data">
        <div class="row g-3">
          <div class="col-md-1 text-left">
            <label for="inputBillTo" class="form-label">Start Date :</label>
          </div>
          <div class="col-md-4 text-left">
            <input type="date" class="form-control" #start_date="ngModel" ngModel name="start_date" required
              [class.is-invalid]="start_date.invalid && start_date.touched" id="inputClientName">
            <div *ngIf="start_date.errors &&(start_date.invalid && start_date.touched)">
              <small class="text-danger" *ngIf="start_date.errors"> Date Of Creation Is Required </small>
            </div>
          </div>
          <div class="col-md-1 text-left">
            <label for="inputBillTo" class="form-label">End Date :</label>
          </div>
          <div class="col-md-4 text-left">
            <input type="date" class="form-control" #end_date="ngModel" ngModel name="end_date" required
              [class.is-invalid]="end_date.invalid && end_date.touched" id="inputClientName">
            <div *ngIf="end_date.errors &&(end_date.invalid && end_date.touched)">
              <small class="text-danger" *ngIf="end_date.errors"> Date Of Creation Is Required </small>
            </div>
          </div>
          <div class="col-md-2 text-left">
            <button mat-raised-button class="addButton" [disabled]="filterApi.invalid" disabled>Filter </button>

          </div>
        </div>
      </form>

      <table class="table table-striped  mt-25 text-left">
        <thead>

          <tr>
            <th scope="col">Sl No</th>
            <th scope="col">API_Name</th>
            <th scope="col">Call_date</th>
            <th scope="col">Called_by</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <ng-template [ngIf]="datalength" [ngIfElse]="show">
            <tr>
              <td colspan="8" style="text-align: center;">
                <p class="nodatafound"> No Data Found... </p>
              </td>
            </tr>
          </ng-template>
          <ng-template #show>
            <tr *ngFor="let orderdata of applogData; let i=index;">            
              <td> {{ i+pageInfo.pageSize*pageInfo.pageIndex+1 }} </td>
              <td> {{ orderdata.api_name }} </td>
              <td> {{ orderdata.api_call_date }} </td>
              <td> {{ orderdata.client_name }} </td>
              <td> {{ orderdata.api_status }} </td>
              <td>
                <button class="addButton" mat-raised-button (click)="viewFilterApi(orderdata.id)">
                  View
                </button>
              </td>
            </tr>

          </ng-template>


        </tbody>
      </table>
      <div *ngIf="datalength == false">        
        <mat-paginator #paginator class="demo-paginator"
         (page)="handlePageEvent($event)"
           [length]="pageInfo.totalElements"
           [pageSize]="pageInfo.pageSize"   
           [pageSizeOptions]="[5, 10, 25,100]"       
           [pageIndex]="pageInfo"
           aria-label="Select page">
        </mat-paginator>               
      </div>
    </div>
  </mat-card-content>

</mat-card>