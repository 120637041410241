import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'adminPanel';
  sideBarOpen=true;
  username:any;

  constructor(public router :Router){
    console.log(environment.production);
    let userdataa: any = localStorage.getItem('userName');
    this.username = atob(userdataa);
  }

  sideBarToggler(){
    this.sideBarOpen=!this.sideBarOpen;
  }
}
