<mat-card class="example-card">

    <mat-card-header>
        <div class="row" style="margin-top:20px ;  width: 100%;">
            <h2 class="hr-lines">Enter App Details</h2>
        </div>
    </mat-card-header>
    <mat-card-content>
        <div class="example-container mt-10">
            <form #addApp="ngForm" enctype="multipart/form-data">
                <div class="row g-4">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App ID :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" required #appid="ngModel" ngModel name="appid" class="form-control"
                            [class.is-invalid]="appid.invalid && appid.touched" id="inputClientName" list="clientList"
                            placeholder="Enter App ID">
                        <div *ngIf="appid.errors &&(appid.invalid && appid.touched)">
                            <small class="text-danger" *ngIf="appid.errors"> App ID Is Required </small>
                        </div>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App Name :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" required #appname="ngModel" ngModel name="appname"
                            [class.is-invalid]="appname.invalid && appname.touched" id="inputClientName"
                            list="clientList" placeholder="Enter App Name">
                        <div *ngIf="appname.errors &&(appname.invalid && appname.touched)">
                            <small class="text-danger" *ngIf="appname.errors"> App Name Is Required </small>
                        </div>
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Co-developer :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="text" class="form-control" required #codeveloper="ngModel" ngModel
                            name="codeveloper" id="inputClientName"
                            [class.is-invalid]="codeveloper.invalid && codeveloper.touched" list="clientList"
                            placeholder="Enter Co-developer name">
                        <div *ngIf="codeveloper.errors &&(codeveloper.invalid && codeveloper.touched)">
                            <small class="text-danger" *ngIf="codeveloper.errors"> Codeveloper Is Required </small>
                        </div>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Date of creation :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <input type="datetime-local" class="form-control" #dateofcreation="ngModel" ngModel
                            name="dateofcreation" required
                            [class.is-invalid]="dateofcreation.invalid && dateofcreation.touched" id="inputClientName">
                        <div *ngIf="dateofcreation.errors &&(dateofcreation.invalid && dateofcreation.touched)">
                            <small class="text-danger" *ngIf="dateofcreation.errors"> Date Of Creation Is Required
                            </small>
                        </div>
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Process Time :</label>
                    </div>
                   
                    <div class="col-md-1 text-left">
                        <input type="number" class="form-control" #process_hours_time="ngModel" [min]=0  ngModel=0 name="process_hours_time"   placeholder="--"                        
                            id="process_hours_time" >
                            <!-- [class.is-invalid]="process_hours_time.invalid && process_hours_time.touched" required -->
                            <!-- <div *ngIf="process_hours_time.errors &&(process_hours_time.invalid && process_hours_time.touched)">
                                <small class="text-danger" *ngIf="process_hours_time.errors"> Hours Is Required
                                </small>
                            </div> -->
                    </div>
                    <div class="col-md-1 text-left">
                        <label for="inputBillTo" class="form-label">Hours</label>
                    </div>
                   
                    <div class="col-md-1 text-left">
                        <input type="number" class="form-control" #process_minutes_time="ngModel" [min]=0 [max]=60 ngModel=0 name="process_minutes_time"    placeholder="--"                        
                            id="process_minutes_time">
                            <!-- [class.is-invalid]="process_minutes_time.invalid && process_minutes_time.touched" required
                            <div *ngIf="process_minutes_time.errors &&(process_minutes_time.invalid && process_minutes_time.touched)">
                                <small class="text-danger" *ngIf="process_minutes_time.errors"> Minutes Is Required
                                </small>
                            </div>                        -->
                    </div>
                    <div class="col-md-1 text-left">
                        <label for="inputBillTo" class="form-label">Minutes</label>
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Report Language :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <mat-checkbox *ngFor="let languages of languages"
                            (change)="getLanguages(languages.value,$event)" required >                                         
                            {{ languages.language }}
                        </mat-checkbox >
                    </div>
                    
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Type of report :</label>
                    </div>
                    <div class="col-md-4 text-left">
                        <mat-checkbox *ngFor="let report_type of report_type"
                            (change)="getreport(report_type.value,$event)" required >                                         
                            {{ report_type.report_type }}
                        </mat-checkbox >
                    </div>
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">App Icon :</label>
                    </div>
                    <div class="col-md-2 text-left">
                        <img class="imageheight" id="blah" [src]="localUrl">
                    </div>
                    <div class="col-md-2">
                        <input type="file" (change)="getimagedata($event)" class="form-control text-center"
                            id="inputClientName" list="clientList" required #appicon="ngModel" ngModel
                            [class.is-invalid]="appicon.invalid && appicon.touched" name="appicon">
                        <div *ngIf="appicon.errors &&(appicon.invalid && appicon.touched)">
                            <small class="text-danger" *ngIf="appicon.errors"> App Icon Is Required </small>
                        </div>
                    </div>
                </div>
                <div class="row g-4 mt-10">
                    <div class="col-md-2 text-left">
                        <label for="inputBillTo" class="form-label">Description :</label>
                    </div>
                    <div class="col-md-8 text-left">
                        <textarea class="form-control" required #description="ngModel" ngModel name="description"
                            [class.is-invalid]="description.invalid && description.touched" rows="2"
                            style="resize:none;" id="comment" placeholder="Description"></textarea>
                        <div *ngIf="description.errors &&(description.invalid && description.touched)">
                            <small class="text-danger" *ngIf="description.errors"> Description Is Required </small>
                        </div>
                    </div>
                </div>
                <div class="row" style="margin-top:20px ;">
                    <mat-card-actions>
                        <div class="col-md-10 text-left">
                        </div>
                        <div class="col-md-2 text-left" >
                            <div *ngIf="appid.value =='' || appname.value =='' || codeveloper.value =='' || dateofcreation.value ==''  ||  description.value =='' || appicon.value ==''">
                                <!-- && appname.value.invalid && codeveloper.value.invalid && dateofcreation.value.invalid && process_hours_time.value.invalid  && process_minutes_time.value.invalid   -->
                                <button mat-raised-button class="addButton" style="margin-right: 10px;    opacity: 0.7; " >Save
                                </button>
                                <button mat-raised-button routerLink="/Apps">Cancel </button>
                            </div>
                            
                            <div  *ngIf="appid.value !='' && appname.value!='' && codeveloper.value !='' && dateofcreation.value!=''  && description.value!=''  && appicon.value!=''"> 
                                <!-- && process_minutes_time.value!='null'  -->
                                 <button mat-raised-button class="addButton" type="submit" style="margin-right: 10px; " (click)="addAppsdata(addApp.value)" >Save
                                </button>
                                <button mat-raised-button routerLink="/Apps">Cancel </button>
                            </div>
                            
                           
                        </div>
                    </mat-card-actions>
                </div>
            </form>
        </div>
    </mat-card-content>

</mat-card>